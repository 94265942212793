/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Tooltip } from "antd";
import {Dropdown} from "../../../../../bit_components/common/dropdown";
import {Button} from "../../../../../bit_components/common/button";
import countriesTimezones from "countries-and-timezones";
import { CountryRegionData } from "react-country-region-selector";
import { useSelector, useDispatch } from "react-redux";
import { userStateSelector, resetAccountSettingsFormState } from "../../../../../redux/reducers/user/userReducer";
import { fetchAccountSetting } from "../../../../../redux/actions/organisation/fetchOrganisationDetails";
import {
  organisationDetailsSelector,
  clearState,
  clearOrganisation,
} from "../../../../../redux/reducers/organisation/organisationDetailsReducer";
import { saveAccountSettings } from "../../../../../redux/actions/user/userProfileAction";
import WarnUnsavedChanges from "../../common/warnUnsavedChanges";
import {
  clearSavedFormValues,
  commonSelector,
} from "../../../../../redux/reducers/common/commonReducer";
import { Pages, UnsavedChangesWarnModal, jsonData, languageStaticOptions } from "../../../../constants/constants";
import { AppService } from "../../../../services/ui/appService";
import { SavedFormValue } from "../../../../models/common/savedFormValue";
import { saveFormValues, saveDirtyFormValues } from "../../../../../redux/actions/common/commonAction";
import { onPage, sidebarNavigate, warnUnsave } from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { getAppHeaderType, languagesCode, urlModification } from "../../../../../utility/appUtil";
import { HeaderType } from "../../../../constants/enums";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

var CountryLanguage = require("country-language");

export const GeneralSettings = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const formName = 'accountSettings';
  const {applyChanges, goTo, isWarnUnsaveOpen} = useSelector(appSelector);
  const history = useHistory();
  const getTimezonesListByCountry = (country: any) => {
    let _country = CountryRegionData.filter(
      (item: any) => (item[0] === country || item[1] === country || item[1] === 'US')
    ).shift()?.[1];
    if (_country) {
      return [...new Set(countriesTimezones.getTimezonesForCountry(_country)?.filter((tz: any) => tz.aliasOf === null)
        ),
      ]?.map((tz: any) => {
        return {
          text: `${tz.name}`,
          value: `${tz.name}`,
        };
      });
    }
    return [];
  };
  // const checkIfCountryExist = (target: any) => {
  //   return CountryRegionData.filter(
  //     (country: any) => country[0] === target
  //   ).shift()?.[0];
  // };
  const initBasicInfoForm = (appUser: any) => {
    /**
     * object to be returned
     */
    return {
      language: appUser?.info?.language ? appUser?.info?.language : "English",
      timezone: appUser?.info?.timezone,
    };
  };
  //   const [timeZones, setTimeZones] = useState<string[]>([]);

  //   const [language, setLanguage] = useState<string[]>([]);

  const [timezonesList, settimezonesList] = useState<any>([]);
  const [languagesList, setLanguagesList] = useState<any>([]);
  // const [change, setChange] = useState(false);
  const [saveInit, setSaveInit] = useState(false);
  const { savedFormValues, isDirty,saveDirtyFormState } = useSelector(commonSelector);
  const { appUser, accountSettingsFormState, user } = useSelector(userStateSelector);
  const [basicInfoForm, setBasicInfoForm] = useState<any>(initBasicInfoForm(appUser));
  const dispatch = useDispatch();
  const { organisation } = useSelector(organisationDetailsSelector);
  const [selectedCountry, setSelectedCountry] = useState(organisation?.result?.postalAddress?.country || '');

  let initialValues: SavedFormValue[] = [
    {
      field: "language",
      value: appUser?.info?.language || '',
    },
    {
      field: "timezone",
      value: appUser?.info?.timezone || '',
    },
  ];

  useEffect(() => {
    setSelectedCountry(organisation?.result?.postalAddress?.country)
  },[organisation])

  useEffect(() => {
    dispatch(sidebarNavigate(SideBarItems.GENERAL));
    if(appUser && getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL){
      dispatch(onPage({onComponent : Pages.ACCOUNT_SETTINGS}));
    } else {
      dispatch(onPage({onComponent : Pages.BIBLIOTHECA_ACCOUNT_SETTINGS}));
    }
  }, []);

  useEffect(()=>{
    dispatch(clearSavedFormValues());
  },[])

  useEffect(() => {
    if (user) {
    }
    return () => {
      dispatch(clearSavedFormValues());
    };
  }, [user]);

  useEffect(() => {
    dispatch(saveFormValues(initialValues));
    setSaveInit(false);
    setBasicInfoForm(initBasicInfoForm(appUser))
  }, [appUser]);

  useEffect(()=>{
  if(saveDirtyFormState?.isError){
    i18next.changeLanguage(appUser?.info?.language?languagesCode(appUser?.info?.language):"English")
  }
  },[saveDirtyFormState])

  const onLanguageChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, language: e });
    onFormChangedCallback({
      target: {
        id: "language",
        type: "input",
        value: e,
      },
    });
    // setChange(true);
  };

  const onTimezoneChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, timezone: e });
    onFormChangedCallback({
      target: {
        id: "timezone",
        type: "input",
        value: e,
      },
    });
    // setChange(true);
  };

  useEffect(() => {
    if (accountSettingsFormState?.isSuccess) {
      AppService.showToast(t(jsonData.AccountSettingsUpdatedSuccessfully));
      dispatch(resetAccountSettingsFormState());
      if(applyChanges && goTo){
        history.push(urlModification(goTo));
        dispatch(warnUnsave({calledBy: null, isWarnUnsaveOpen: false}));
      }
    }

    if (accountSettingsFormState?.isError) {
      AppService.showToast(`${t(jsonData.SomeErrorOccured)}.`, true);
      dispatch(resetAccountSettingsFormState());
      isWarnUnsaveOpen && dispatch(warnUnsave({calledBy: null, isWarnUnsaveOpen: false}));
    }
  }, [accountSettingsFormState]);

  useEffect(() => {
    dispatch(fetchAccountSetting(appUser ? appUser?.organization.id : ''))

    return () => {
      dispatch(clearState());
      dispatch(clearOrganisation());
    };
  }, []);

  const checkIfTimezoneExist = (target: any, parent: any) => {
    return getTimezonesListByCountry(parent)
      .filter((tz: any) => tz?.name === target)
      .shift()?.value;
  };

  useEffect(() => {
    CountryLanguage.getCountryMsLocales(

      CountryRegionData.filter(
        (item: any) => (item[0] === selectedCountry || item[1] === selectedCountry || item[1] === 'US')
      ).shift()?.[1],
      function (err: any, locales: any) {
        if (err) {
          console.log(err);
        } else {
          var tempLanguage: string[] = [];

          if (locales) {
            locales.forEach(function (locale: any) {
              tempLanguage.push(locale.displayName.split("-")[0].trim());
            });

            setLanguagesList(
              tempLanguage.map((language: any) => {
                return { text: language, value: language };
              })
            );
            settimezonesList(getTimezonesListByCountry(selectedCountry));
            // if (tempLanguage.indexOf(basicInfoForm.language) === -1) {
            //   setBasicInfoForm({ ...basicInfoForm, language: "English" });
            // }
          }
        }
      }
    );
  }, [selectedCountry]);

  const onCountryChange = (e: any) => {
    settimezonesList(getTimezonesListByCountry(e));
    setBasicInfoForm({ ...basicInfoForm, timezone: "" });
  };

  const onFormChangedCallback = (e: any) => {
    if (savedFormValues) {
      if (e.target) {
        dispatch(
          saveDirtyFormValues({
            target: e.target,
            savedFormValues: savedFormValues,
          })
        );
      }
    }
  };

  const onFinish = (data: any) => {
    if (1) {
      const user: any = {
        id: appUser?.id ?? '',
        info: { ...appUser?.info, language: basicInfoForm.language, timezone: basicInfoForm.timezone }
      };
      i18next.changeLanguage(basicInfoForm?.language?languagesCode(basicInfoForm?.language):"English")
      setSaveInit(true);
      setTimeout(() => {
        dispatch(saveAccountSettings(user));
        dispatch(clearSavedFormValues());
      }, 200);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      language: basicInfoForm?.language,
    });
  }, [basicInfoForm]);

  return (
    <>
      <WarnUnsavedChanges
        ignorePrompt={saveInit}
        navigateOnCancel={true}
        title={t(UnsavedChangesWarnModal.TITLE)}
        content={t(UnsavedChangesWarnModal.CONTENT)}
        cancelBtnText={t(UnsavedChangesWarnModal.DISCARD_BTN_TEXT)}
        confirmBtnText={t(UnsavedChangesWarnModal.CONFIRM_BTN_TEXT)}
        isDirty={isDirty ? isDirty : false}
        onConfirm={form.submit}
      />

      <div className="ml-23 mt-25">
        <Row gutter={[33, 33]} className="accHed pl-vw general-margin">
          <Col xs={8} sm={8} md={12} lg={9} xl={7} xxl={7} className="mb-10">
            <span className="accSettHedLeft">{t(jsonData.LanguageSettings)}</span>
          </Col>
          <Col xs={10} sm={10} md={4} lg={8} xl={12} xxl={13}></Col>
          <Col xs={6} sm={6} md={8} lg={7} xl={5} xxl={4} className="general-padding">
            <div>
              <Form.Item className="mb-0 w-100 hedMinHeight">
                {!isDirty ? (
                  <Button type="smallBlack" disabled htmlType="submit">
                     <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Saved)}>
                    {t(jsonData.Saved)}
                      </Tooltip>
                  </Button>
                ) : (
                  <Button type="smallBlack" onClick={() => { form.submit() }} htmlType="submit">
                   <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.ApplyChanges)}>
                    {t(jsonData.ApplyChanges)}
                    </Tooltip>
                  </Button>
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row className="mt-29 pl-vw">
          <Col span={24}>
            <Form form={form} name={formName} layout="vertical" requiredMark={false} onFinish={onFinish} onChange={onFormChangedCallback}>
              <Row className="mb-30">
                <Col sm={9} md={9} lg={7}>
                <Form.Item
                    className="mb-0 profile-label-cls"
                    label={t(jsonData.Language)}
                    name="language"
                    initialValue={basicInfoForm.language}
                    rules={[{ required: true, message: t(jsonData.LanaguageRequired)}]}
                  >
                    <Dropdown
                      value={basicInfoForm?.language}
                      optionValue={languageStaticOptions}
                      onChange={onLanguageChange}
                      tabIndex={0}
                    ></Dropdown>
                  </Form.Item>
                  {/* <Form.Item
                    className="mb-0 profile-label-cls"
                    label={t("Language")}
                    name="language"
                    initialValue={basicInfoForm.language}
                    rules={[{ required: true, message: "Lanaguage required" }]}
                  >
                    <Dropdown
                      value={basicInfoForm.language}
                      optionValue={languagesList}
                      onChange={onLanguageChange}
                    ></Dropdown>
                  </Form.Item> */}
                </Col>
              </Row>
              {/* <Row className="mb-30">
                <Col sm={9} md={9} lg={7}>
                  <Form.Item
                    className="mb-0 profile-label-cls"
                    label="Timezone"
                    name="timezone"
                    initialValue={basicInfoForm.timezone}
                    rules={[{ required: true, message: "Timezone required" }]}
                  >
                    <Dropdown
                      value={basicInfoForm.timezone}
                      optionValue={timezonesList}
                      onChange={onTimezoneChange}
                    ></Dropdown>
                  </Form.Item>
                </Col>
              </Row> */}
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};
