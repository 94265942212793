import React from "react";
import { jsonData } from "../../../constants/constants";
import CustomFilter from "../../stateless/common/customTableFilter";
import { Tooltip } from "antd";
//dropdownvalues
export const optionValues = [
  {
    text: "Day",
    value: "day",
  },
  {
    text: "Week",
    value: "week",
  },
  {
    text: "Month",
    value: "month",
  },
  {
    text: "Quarter",
    value: "quarter",
  },
  {
    text: "Year",
    value: "year",
  },
  {
    text: "Custom",
    value: "custom",
  },
];

//csv keys
export const headers = [
  // { header: "", key: "" },
  { header: "Date & Time", key: "dateTimeStamp" },
  { header: "UserID", key: "userID" },
  { header: "Category", key: "category" },
  { header: "Event", key: "event" },
  { header: "Description", key: "content" },
];
//Table Header column
export const tablecolumn = (
  userIdItem: any,
  descriptionItem: any,
  categoryItem: any,
  eventtitem: any,
  filteredInfo: any,
  sortedInfo: any,
  t: any,
  selectedDeviceStateUpdate: any,
  selectedDeviceStates: any
) => {
  return [
    {
      title: ( <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title= {t(jsonData.DateTime)}>
        <div className="dateTime" onClick={(e) => e.stopPropagation()}>
          {t(jsonData.DateTime)}
        </div>
        </Tooltip>
      ),
      dataIndex: "dateAndtime",
      key: "dateTimeStamp",
      ellipsis: true,
      filteredValue: filteredInfo?.dateAndtime || null,
      sortOrder: sortedInfo?.columnKey === "dateTimeStamp" && sortedInfo?.order,
      sorter: (a: any, b: any) => null,
      // onFilter: (value: any, record: any) => record.location? record?.location?.includes(value): null,
    },
    // {
    //   title: "key",
    //   dataIndex: "key",
    // },
    {
      title: ( <div className="headText"  onClick={(e) => e.stopPropagation()}>
      <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.UserID)}>
        <p className="tableHeadingFixed user">
          {t(jsonData.UserID)} </p>
          </Tooltip>
          <CustomFilter
            optionList={userIdItem}
            selectedData={selectedDeviceStateUpdate}
            selectedItems={selectedDeviceStates?.userID ?? []}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-userId"
            wrapperClassName="state-multiselect"
            coulmnName="userID"
            filterName={t(jsonData.userIdFilter)}
          />
        </div>
      ),
      dataIndex: "userID",
      key: "userID",
      ellipsis: true,
      filteredValue: filteredInfo?.userID || null,
      sortOrder: sortedInfo?.columnKey === "userID" && sortedInfo?.order,
      // filters: userIdItem,
      sorter: (a: any, b: any) => null,
      // onFilter: (value: any, record: any) => record.location? record?.location?.includes(value): null,
    },
    {
      title: (  <div className="headText" onClick={(e) => e.stopPropagation()}>
      <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title= {t(jsonData.Category)}>
        <p> {t(jsonData.Category)} </p>
        </Tooltip>
          <CustomFilter
            optionList={categoryItem}
            selectedData={selectedDeviceStateUpdate}
            selectedItems={selectedDeviceStates?.category ?? []}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-category"
            wrapperClassName="state-multiselect"
            coulmnName="category"
            filterName={t(jsonData.categoryFilter)}
          />
        </div>
      ),
      dataIndex: "category",
      key: "category",
      ellipsis: true,
      filteredValue: filteredInfo?.category || null,
      sortOrder: sortedInfo?.columnKey === "category" && sortedInfo?.order,
      // filters: categoryItem,
      sorter: (a: any, b: any) => null,
      // onFilter: (value: any, record: any) => record.location? record?.location?.includes(value): null,
    },
    {
      
      title: ( <div  className="headText" onClick={(e) => e.stopPropagation()}>
      <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Event)} >
         <p className="tableHeadingFixed"> {t(jsonData.Event)} </p>
         </Tooltip>
          <CustomFilter
            optionList={eventtitem}
            selectedData={selectedDeviceStateUpdate}
            selectedItems={selectedDeviceStates?.event ?? []}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-event"
            wrapperClassName="state-multiselect"
            coulmnName="event"
            filterName={t(jsonData.eventFilter)}
            allowSearch={true}
          />
        </div>
      ),
      dataIndex: "event",
      ellipsis: true,
      key: "event",
      filteredValue: filteredInfo?.event || null,
      sortOrder: sortedInfo?.columnKey === "event" && sortedInfo?.order,
      // filters: eventtitem,
      sorter: (a: any, b: any) => null,
      // onFilter: (value: any, record: any) => record.location? record?.location?.includes(value): null,
    },
    {
      title: (<div className=" headText description" onClick={(e) => e.stopPropagation()}>
      <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Description)}>
         <p> {t(jsonData.Description)} </p>
         </Tooltip>
          <CustomFilter
            optionList={descriptionItem}
            selectedData={selectedDeviceStateUpdate}
            selectedItems={selectedDeviceStates?.content ?? []}
            allSelectedText={t(jsonData.AllStates)}
            placeholder={t(jsonData.SelectState)}
            optionListClass="state-optionList-description"
            wrapperClassName="state-multiselect"
            coulmnName="content"
            filterName={t(jsonData.descriptionFilter)}
          />
        </div>
      ),
      dataIndex: "content",
      key: "content",
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === "content" && sortedInfo?.order,
      filteredValue: filteredInfo?.content || null,
      // filters: descriptionItem,
      sorter: (a: any, b: any) => null,
      // onFilter: (value: any, record: any) => record.location? record?.location?.includes(value): null,
    },
  ];
};
