import secureLocalStorage from "react-secure-storage";
import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { DeviceUrl } from "../../constants/apiConstants";
import qs from 'qs'

export default class DeviceService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.DEVICE));
  } 

  // fetch device list for org
  async fetchDeviceList(orgId:string,locations:any,deviceTypes:any,name:any,page:any, searchParam: string): Promise<any> {
    let params :any = {}
    let endpoint
    if (locations) {
      params.locations = locations
    }
    if(deviceTypes) {
      params.deviceTypes = deviceTypes
    }
    if(name) {
      params.name = name
    }
    if(searchParam) {
      params.searchParam = searchParam
    }
    if(page) {
      params.page = page
    }
    params.paranoid = true
    if(page) {
      endpoint = `${DeviceUrl.DEVICE}?organisationId=${orgId}&page=${page}`;
    } else {
      endpoint = `${DeviceUrl.DEVICE}?organisationId=${orgId}`;
    }

    const response: any = await this.get(endpoint, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    });
    return response?.data?.result?.message;
  }

  async fetchPartNameList(orgId:string): Promise<any> {
    
    const endpoint = `${DeviceUrl.DEVICE_PARTNAMES}?organisationId=${orgId}`;
    const response: any = await this.get(endpoint);
    
    if (response?.data?.result) {
      return response.data.result; 
    }
    return [];
  }

  // async fetchDeviceListFilter(orgId:string,locations:any): Promise<any> {
  //   let params :any = {}
  //   let endpoint
  //   if (locations) {
  //     params.locations = locations
  //   }
  //   params.paranoid = true
  //     endpoint = `${DeviceUrl.DEVICE_FILTER}?organisationId=${orgId}`;
  //   const response: any = await this.get(endpoint, {
  //     params: params,
  //     paramsSerializer: (params) => {
  //       return qs.stringify(params)
  //     },
  //   });
  //   return response?.data?.result?.message;
  // }

  async fetchDeviceFilter(orgId:string,locations:any): Promise<any> {
    let params :any = {}
    if (locations) {
      params.locations = locations
    }
    params.paranoid = true
    let endpoint = `${DeviceUrl.DEVICE_FILTER}?organisationId=${orgId}`;
    const response: any = await this.get(endpoint, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    });
    return response?.data?.result?.message;
  }

  async fetchDevicesAtHierarchy(locations:string): Promise<any> {
    let params :any = {}
    if (locations) {
      params.locations = [locations]
    }
    let endpoint = `${DeviceUrl.DEVICE}`;
    const response: any = await this.get(endpoint, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
    });
    return response?.data?.result?.message?.count;
  }

  async fetchDevicesHealthByOrgId(parameters): Promise<any> {
    let endpoint = `${DeviceUrl.DEVICE_HEALTH}`;
    let params : any = {}
    params.organisationId = parameters?.organizationId

    if(parameters?.isDashboard){
      params.isDashboard = parameters?.isDashboard
    } else {
      params.limit=250
    }

    const splitFunction = (sortBy) =>{
      let splittedArray = sortBy.split('-')
      params.sortBy = splittedArray[0]
      params.sortOrder = splittedArray[1]
    }

    if(parameters?.locations){
      params.locations = parameters?.locations
    }
    if(parameters?.products){
      params.productList = parameters?.products
    }
    if(parameters?.states){
      params.deviceStateList = parameters?.states
    }
    if(parameters?.page) {
      params.page = parameters?.page
    }
    if(parameters?.devices) {
      params.deviceList = parameters?.devices
    }
    if(parameters?.sortBy === undefined){
      splitFunction("state-desc")
    }
    else{
      splitFunction(parameters?.sortBy)
    }
    const response: any = await this.get(endpoint, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    });
    if(response?.data?.result)
      return response?.data?.result
    else 
      return null
  }

  async fetchDeviceTypeList(parameters:any): Promise<any>{
    let params : any = {}
    params.organizationId = parameters?.organizationId
    params.isOrgFilter = parameters?.isOrgFilter
    let endpoint = `${DeviceUrl.DEVICE_TYPE}`
    const response: any = await this.get(endpoint,{
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
    if(parameters?.isOrgFilter){
      return response?.data?.result;
    }else{
      return response?.data?.result?.message?.rows;
    }
  }

  async fetchDeviceStates(): Promise<any> {
    let endpoint = `${DeviceUrl.DEVICE_STATES}`
    const response: any = await this.get(endpoint)
    return response?.data?.result;
  }

  async fetchDeviceComponents(parameters): Promise<any> {
    let endpoint = `${DeviceUrl.DEVICE_HEALTH}/${parameters.id}`;
    let params : any = {}

    const splitFunction = (sortBy) =>{
      let splittedArray = sortBy.split('-')
      params.sortBy = splittedArray[0]
      params.sortOrder = splittedArray[1]
    }

    if(parameters?.sortBy === undefined){
      splitFunction("severity-desc")
    }
    else{
      splitFunction(parameters?.sortBy)
    }
    let header : any = {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    }
    if(parameters.cancelToken){
      header.cancelToken = parameters.cancelToken.token
    }
    const response: any = await this.get(endpoint, header)
    if(response?.data?.result)
      return response?.data?.result;
    else 
      return null
  }

  async fetchDeviceInfo(parameters): Promise<any> {
    let endpoint = `${DeviceUrl.DEVICE}/${parameters.id}`;
    const response: any = await this.get(endpoint)
    if(response?.data?.result)
      return response?.data?.result;
    else 
      return null
  }

  async updateDeviceInfo(parameters): Promise<any> {
    let endpoint = `${DeviceUrl.DEVICE}/${parameters.id}`;
    const response: any = await this.put(endpoint, parameters.device)
    if(response?.data?.result)
      return response?.data?.result;
    else 
      return null
  }

  async addDevice(parameters): Promise<any> {
    let endpoint = `${DeviceUrl.DEVICE}`;
    const response: any = await this.post(endpoint, parameters)
    if(response?.data?.result){
      return response?.data?.result
    } else if (response?.data?.error?.message) {
      return response?.data
    }
    else 
      return null
  }

  async deleteDevice(id): Promise<any> {
    let endpoint = `${DeviceUrl.DEVICE}/${id}`;
    const response: any = await this.delete(endpoint)
    if(response?.data?.result)
      return response?.data?.result;
    else 
      return null
  }

  async fetchDeviceConfigViews(parameters: any): Promise<any> {
    const { deviceId, userName, availableViews } = parameters;
    let localeInfo:any = secureLocalStorage.getItem('locale')
    
    let params = {
      did: deviceId,
      username: userName,
      culture: localeInfo.culture,
    };
    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/views/`;

    if(availableViews){
      return availableViews;
    }
    const response: any = await this.get(endpoint, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    if(response.status === 200) {
      if(response.data?.length > 1){
        response.data = response.data?.filter((view) => {
          if(view?.Name !== 'Raw')
            return view
        })
      }
      return response.data;
    }
    return null;
  }

  async fetchNodeContent(parameters: any): Promise<any> {
    const { deviceId, userName, viewName, Id, nodeIds, cachedNodeContent} = parameters;
    
    if(cachedNodeContent){
      const nodeIDS = nodeIds.split(';');

      return cachedNodeContent.map((item: any, index: number) => ({
        ...item,
        FullNodeId: nodeIDS[index],
        DeviceXmlVersions: {}
      }));
    }

    let localeInfo:any = secureLocalStorage.getItem('locale')

    let params = {
      did: deviceId,
      username: userName,
      culture:  localeInfo?.culture,
      vn : viewName,
      nid: nodeIds
    };
    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/views/${Id}/nodes/all/`;

    const response: any = await this.get(endpoint, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    if(response.status === 200) {
      const uniqueNodeContent = Object.values(response.data.reduce((acc: any, obj: any) => {
        acc[obj.FullNodeId] = obj;
        return acc;
      }, {}));
      return uniqueNodeContent;
    }
    return null;
  }

  async fetchConfigNodes(parameters: any): Promise<any> {
    const {
      deviceId,
      userName,
      viewName,
      Id,
      st,
      availableNode,
      signal,
    } = parameters;
   
    let localeInfo:any = secureLocalStorage.getItem('locale')
  
    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/views/${Id}/`;
    let url = `${endpoint}?did=${deviceId}&username=${userName}&culture=${localeInfo.culture}&vn=${viewName}`;

    if (st) {
      url = `${url}&st=${st}`;
    }

    if (availableNode) {
      return availableNode;
    }

    let response = null;
    if (signal) {
      response = await this.get(url, {
        cancelToken: signal?.token,
      });
    } else {
      response = await this.get(url);
    }

    if (response.status === 200) {
      return response.data;
    }
    return null;
  }

  async getConfigState(parameters: any): Promise<any> {
    const { deviceId, userName, prevState} = parameters;

    let params = {
      did: deviceId,
      username: userName,
    };
    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/status/`;

    const response: any = await this.get(endpoint, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    
    if(response.status === 200) {
      const newResponse = response.data.Devices[0];
      if(newResponse?.ProcessingWorkItem){
        let latestdWorkItemTime: string
        newResponse?.WorkItems?.map((item: any) =>{
          if(!latestdWorkItemTime){
            latestdWorkItemTime = item?.CreatedTimestamp;
          } else if(latestdWorkItemTime < item?.CreatedTimestamp) {
            latestdWorkItemTime = item?.CreatedTimestamp;
          }
        })

        if(latestdWorkItemTime){
          newResponse.latestdWorkItemTime = latestdWorkItemTime
        }

      }
      return {
        response: newResponse,
        previousResponse: prevState
      }
    }
    return null;
  }

  async getDevicesStatus(parameters: any): Promise<any> {
    const { deviceIdList, userName } = parameters;

    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/status/?username=${userName}`;

    const response: any = await this.post(endpoint, { DeviceIds: deviceIdList });

    if (response.status === 200) {
      let deviceStatusList = response.data.Devices;

      let deviceStatuses = {};

      deviceIdList.forEach(async (currentDeviceId: string, index: number) => {
        const currentDeviceStatus = deviceStatusList[index];

        deviceStatuses = {
          ...deviceStatuses,
          [currentDeviceId]: currentDeviceStatus,
        };
      });

      return deviceStatuses;
    }
    return null;
  }


  async getConfiguration(parameters: any): Promise<any> {
    const { userName, deviceId } = parameters;

    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/work/request/?username=${userName}&did=${deviceId}`;

    const response: any = await this.put(endpoint);
    
    if(response.status == 200) {
      return "Success";
    }
    return null; 
  }

  async lockDeviceState(parameters: any): Promise<any> {
    const { userName, deviceId } = parameters;

    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/status/lock/?username=${userName}&did=${deviceId}`;

    const response: any = await this.put(endpoint);

    if(response.status === 200) {
      return response.data;
    }
    return null;  
  }

  async unLockDeviceState(parameters: any): Promise<any> {
    const { userName, deviceId } = parameters;
    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/status/unlock/?username=${userName}&did=${deviceId}`;

    const response: any = await this.put(endpoint);
    if(response.status === 200) {
      return response.data;
    }
    return null; 
  }

  async getConfigDevices(parameters: any): Promise<any> {
    const { userName, deviceId } = parameters;

    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/${deviceId}/copy/devices/?username=${userName}`;

    const response: any = await this.get(endpoint);
    
    if(response.status === 200) {
      return response.data;
    }
    return null;
  }

  async storeConfiguration(parameters: any): Promise<any> {

    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/work/save`;
    const response: any = await this.post(endpoint, parameters)
    
    if(response.status === 200) {
      return response.data;
    }
    return null;
  }

  async copyConfiguration(parameters: any): Promise<any> {
    const { userName, deviceId, payload } = parameters;

    let endpoint = `${process.env.REACT_APP_DEVICE_CONFIG_URL}/${deviceId}/copy?username=${userName}`;
    const response: any = await this.post(endpoint, payload);

    if(response.status === 200) {
      return response.data;
    }
    return null;
  }
}