/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetPwd } from "../../../../../../redux/actions/user/forgotPwdAction";
import {
  clearAuthInput,
  clearFormStateError,
  clearResetPwdFormState,
  clearState,
  userStateSelector,
} from "../../../../../../redux/reducers/user/userReducer";
import { UserAuthInput } from "../../../../../models/user/userAuthInput";
import { AppRoutes } from "../../../../../constants/enums";
import { ResetPwdForm } from "../../../../stateless/user/auth/resetPwdForm";
import { Messages } from "../../../../../constants/messages";
import { urlModification } from "../../../../../../utility/appUtil";
import { AppService } from "../../../../../services/ui/appService";
import { jsonData } from "../../../../../constants/constants";
import { useTranslation } from 'react-i18next';

export const ResetPwd = (props: any) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const { match, location } = props;
  //debugger;
  const history = useHistory();
  const dispatch = useDispatch();
  const { formState, resetPwdSuccess, resetPwdFormState } = useSelector(userStateSelector);

  const layout = {
    labelCol: {
      span: 24,
      // offset: 2,
    },
    wrapperCol: {
      span: 24,
    },
  };

  //debugger;
  useEffect(() => {
    dispatch(clearResetPwdFormState())
    dispatch(clearFormStateError());
    return () => {
      dispatch(clearState());
      dispatch(clearFormStateError());
      dispatch(clearResetPwdFormState())
    };
  }, []);

  useEffect(() => {
    //debugger;
    if (formState?.isError) {
      AppService.showToast(t(jsonData.SomeErrorOccured), true);
      dispatch(clearState());
    } 
    else if (resetPwdSuccess) {
      dispatch(clearState());
      dispatch(clearAuthInput());
      setTimeout(() => {
        history.push(urlModification(AppRoutes.VALIDATE_EMAIL), { message: t(Messages.PWD_RESET_SUCCESS) });
      }, 200);
    }
  }, [resetPwdSuccess, formState?.isError]);

  useEffect(() => {
    //debugger;
    if (formState?.errorStack && formState?.errorStack.message) {
      form.resetFields();
      dispatch(clearAuthInput());
    }
  }, [formState?.errorStack]);

  const onResetPwdCallback = (userAuth: UserAuthInput) => {
    //debugger;
    dispatch(resetPwd(userAuth));
  };

  return (
    <ResetPwdForm
      form={form}
      formLayout={layout}
      location={location}
      errorMsg={
        formState?.errorStack && formState?.errorStack.message
          ? formState?.errorStack.message
          : ""
      }
      resetPwdCallback={onResetPwdCallback}
    />
  );
};


