/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Row, Col, Form, Tooltip } from 'antd'
import { onPage, sidebarNavigate, warnUnsave } from '../../../../redux/actions/app/appAction';
import { ALERT_CONFIG_DEFAULT_LIMIT, ALERT_CONFIG_PAGE_SIZE, Pages, UnsavedChangesWarnModal,AlertTypeDropdown ,filterOptions, jsonData } from '../../../constants/constants';
import { SideBarItems } from '../../../constants/sideBarConstants';
import WarnUnsavedChanges from '../common/warnUnsavedChanges';
import AlertSeverity from './alertDropdown/alertSeverity';
import SquareCheckbox from './alertCheckbox/checkBox';
import AlertToggle from './alertToggleSwitch';
import { Button } from "../../../../bit_components/common/button";
import { cameFromOrganisation, getHeight, translateOptions, urlModification } from '../../../../utility/appUtil';
import { deleteFromAlertConfigChanges, setAlertConfigUpdatedInfo, setAlertConfigChanges, clearAlertConfigList, clearAlertConfigChanges, AlertConfigSelector, clearAlertConfigUpdatedInfo } from '../../../../redux/reducers/alertConfig/alertConfigReducer';
import './alertConfig.less'
import { fetchAlertConfig, updateAlertConfig } from '../../../../redux/actions/alertConfig/alertConfigAction';
import { appSelector } from '../../../../redux/reducers/app/appReducers';
import { userStateSelector } from '../../../../redux/reducers/user/userReducer';
import Loader from '../../stateless/common/spinner';
import { AntdInfinityTable } from '../../stateless/common/antdTable/antdInfininityTable';
import { getTableFilterData, onlyUnique, prepareFilterList } from '../../../../utility/utils';
import { Messages } from '../../../constants/messages';
import { AppService } from '../../../services/ui/appService';
import { fetchEventsInfo } from '../../../../redux/actions/eventLogs/eventListAction';
import { commonSelector, setIsDirty } from '../../../../redux/reducers/common/commonReducer';
import EmailCheckbox from './alertCheckbox/emailCheckBox';
import SmsCheckbox from './alertCheckbox/smsCheckBox';
import { AppRoutes, UserRole } from '../../../constants/enums';
import AlertInput from './alertInput';
import AdminCheckbox from './alertCheckbox/adminCheckBox';
import { Dropdown } from '../../../../bit_components/common/dropdown';
import { Redirect } from "react-router-dom"
import { alertSettings, NotificationBellIcon } from '../../../images';
import { useTranslation } from 'react-i18next';
import CustomFilter from '../../stateless/common/customTableFilter';


export const AlertConfigSettings = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory();
    const { alertConfigList, alertConfigFormState, alertConfigUpdatedInfo, alertConfigChanges, updateAlertConfigFormState } = useSelector(AlertConfigSelector)
    const { selectedOrganisation, applyChanges, goTo, isWarnUnsaveOpen } = useSelector(appSelector);
    const { isDirty } = useSelector(commonSelector);
    const { appUser } = useSelector(userStateSelector);
    const [screenHeight, setScreenHeight] = useState(document.body.clientHeight)
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth)
    const [unfilteredData, setUnfilteredData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [tableData, setTableData] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [scrollHeight, setScrollHeight] = useState(200)
    const [changed, setChanged] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [scrolledToEnd, setScrolledToEnd] = useState(false)
    const [severityList, setSeverityList] = useState()
    const [eventIdList, setEventIdList] = useState()
    const [type, setType] = useState()
    const [eventList, setEventList] = useState()
    const [categoryList, setCategoryList] = useState()
    const [clearingEventList, setClearingEventList] = useState()
    const [filteredInfo, setFilteredInfo] = useState<any>(null)
    const [sortedInfo, setSortedInfo] = useState(null)
    const [saveInit, setSaveInit] = useState<any>(false)
    const [inValid, setInValid] = useState<any>(false)
    const [alertType, setAlertType] = useState<any>(AlertTypeDropdown.ACTIVE_VALUE)
    // for active column
    const [activateAll, setActivateAll] = useState(null)
    const [totalActivated, setTotalActivated] = useState([])
    const [tableColumn, setTableColumn] = useState<any>([])

    // for sms column
    const [selectAllSms, setSelectAllSms] = useState(null)
    const [totalSmsNotified, setTotalSmsNotified] = useState([])

    // for email column
    const [selectAllEmail, setSelectAllEmail] = useState(null)
    const [totalEmailNotified, setTotalEmailNotified] = useState([])

    //for administrator column
    const [selectAllAdmin, setSelectAllAdmin] = useState(null)
    const [totalAdminNotified, setTotalAdminNotified] = useState([])

     //for banner column
     const [selectAllBanner, setSelectAllBanner] = useState(null)
     const [totalBannerNotified, setTotalBannerNotified] = useState([])

     const [selectedDeviceStates, setSelectedDeviceStates] = useState<any>({});
     const [reset, setReset] = useState<boolean>(false)

    const AlertColumn = {
        ACTIVE : 'isActive',
        SMS : 'isSMSNotify',
        EMAIL : 'isEmailNotify',
        ADMINISTRATOR:'isOnlyAdminNotify',
        BANNER:"isPopUpBanner"
    }

    useEffect(() => {
        dispatch(fetchEventsInfo(null)) // using for alert severity dropdown
        dispatch(sidebarNavigate(SideBarItems.ALERT_CONFIG_SETTINGS))
        dispatch(onPage({ onComponent: Pages.SETTINGS }))
        setLoading(true) 
        let params = {
            organizationId : getOrgId(),
            isActive : alertType === AlertTypeDropdown.ALL_VALUE ? null : alertType
        }
        dispatch(fetchAlertConfig(params))
        const node = document.body
        const resizeObserver = new ResizeObserver((entries) => {
            setScreenHeight(node?.clientHeight)
            setScreenWidth(node?.clientWidth)
        });
        resizeObserver.observe(node);
        return () => {
            dispatch(clearAlertConfigList())
            dispatch(clearAlertConfigChanges())
        }
    }, []);

    const getOrgId = () => {
        let orgId = appUser?.organization.id as string;
        if (cameFromOrganisation()) {
            orgId = selectedOrganisation?.id;
        }
        return orgId;
    };

    useEffect(() => {
        if (alertConfigFormState?.isSuccess && !alertConfigList?.length) {
            setLoading(false)
        }
        if (alertConfigFormState?.isError) {
            setLoading(false)
        }
    }, [alertConfigFormState])

    useEffect(() => {
        if (alertConfigList?.length) {
            setUnfilteredData(alertConfigList)
            setFilteredData(alertConfigList)
            setTableData(alertConfigList.slice(currentIndex, currentIndex + ALERT_CONFIG_PAGE_SIZE))
            setCurrentIndex(currentIndex + ALERT_CONFIG_PAGE_SIZE)
            setLoading(false)
            updateTotalSelected(alertConfigList)

            //filter table  by dropdown severity
            let severityItems = alertConfigList?.map((data: any) => data?.severity?.toString().toLowerCase());
            const severityUniqueItems = severityItems?.filter(onlyUnique)?.filter((x:any) => x?.toString()?.toLowerCase() !== 'good')
            setSeverityList(prepareFilterList(severityUniqueItems))

            //filter table  by dropdown eventid
            let eventIdlistItems = alertConfigList?.map((data: any) => data.eventId);
            const eventIdUniqueLocation = eventIdlistItems?.filter(onlyUnique);
            setEventIdList(prepareFilterList(eventIdUniqueLocation))

            //filter table  by dropdown event
            let eventlistItems = alertConfigList?.map((data: any) => data.event);
            const eventUniqueLocation = eventlistItems?.filter(onlyUnique);
            setEventList(prepareFilterList(eventUniqueLocation))

            //filter table  by dropdown category
            const categorytlistItems = alertConfigList?.map(
                (data: any) => data.category
            );
            const eventtuniquecategory = categorytlistItems?.filter(onlyUnique);
            setCategoryList(prepareFilterList(eventtuniquecategory))

            const eventType = alertConfigList?.map((data: any) => data.type);
            const uniqueEventType = eventType?.filter(onlyUnique);
            setType(prepareFilterList(uniqueEventType))

            //filter table  by dropdown clearingEvent
            const clearingEventItems = alertConfigList?.map((data: any) => data.clearingEvent);
            const clearingEventUniqueLocation = clearingEventItems?.filter(onlyUnique)
            clearingEventUniqueLocation?.length && setClearingEventList(prepareFilterList(clearingEventUniqueLocation))

        }
    }, [alertConfigList])

    const newFunc = (filters: any, sorter: any) => {
        if(filters){
        getTableFilterData(filters, unfilteredData, unfilteredData).then((tableFilterData: any) => {
            getSortedData(sorter, tableFilterData).then((data) => {
                setLoading(true)
                setFilteredData(data)
                setTableData(data?.slice(0, ALERT_CONFIG_PAGE_SIZE))
                setCurrentIndex(0 + ALERT_CONFIG_PAGE_SIZE)
                updateTotalSelected(data)
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            })
        }).catch((err) => {
            console.log('Failed to get Filtered data ', err)
        })
    }
    }

    useEffect(() => {
        newFunc(filteredInfo, sortedInfo);
    }, [filteredInfo, sortedInfo, unfilteredData])

    const selectedDeviceStateUpdate = (data: any, key: any,reset:any) => {
        const updatedDeviceStates = { ...selectedDeviceStates };
        setReset(reset)
        if (updatedDeviceStates?.hasOwnProperty(key)) {
            setSelectedDeviceStates({...updatedDeviceStates,[`${key}`]:data[key]})
        } else {
            setSelectedDeviceStates({...updatedDeviceStates,...data})
        }
      };

    useEffect(() => {
      const filteredObject = Object?.keys(selectedDeviceStates)?.reduce(
        (acc: any, key: any) => {
          const value = selectedDeviceStates[key];
          if (value !== "" && (!Array.isArray(value) || value.length > 0)) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      if (Object?.keys(filteredObject).length === 0) {
        setFilteredInfo(reset ? {} : null);
      } else {
        setFilteredInfo(filteredObject);
      }
    }, [selectedDeviceStates,reset]);

    // let sortedEle = document.querySelector(".ant-table-column-sorter.ant-table-column-sorter-full");

    // const filterSortingdata = () => {
    //   let selectedSorted: any = document.querySelector('.ant-table-column-sorter-inner');
  
    //   if(selectedSorted) {
    //       selectedSorted.focus();
    //       selectedSorted.click();
    //     }
    // }
    
    // if (sortedEle) {
    //   sortedEle.setAttribute("tabIndex", "0");
    //   sortedEle.addEventListener("keydown", (event: any) => {
    //     if(event.keyCode === 13)
    //       filterSortingdata();
    //     })
    // }

    // useEffect(()=>{
    //  setTableColumn(alertConfigColumns())
    // },[alertConfigFormState?.isSuccess,loading])

    const alertConfigColumns = () => {    

        let columns = [
            {
                title:  <div className=" headText" onClick={(e) => e.stopPropagation()}>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Severity)}>
                    <p>{t(jsonData.Severity)} </p>
                    </Tooltip>
                <CustomFilter 
                optionList={severityList} 
                selectedData={selectedDeviceStateUpdate}
                selectedItems={selectedDeviceStates?.severity??[]}
                allSelectedText={t(jsonData.AllStates)}
                placeholder={t(jsonData.SelectState)}
                optionListClass="state-optionList-severity"
                wrapperClassName="state-multiselect"
                coulmnName="severity"
                filterName={t(jsonData.severityFilter)}
                />
                </div>,
                dataIndex: "severity",
                key: "severity",
                ellipsis: true,
                filteredValue: filteredInfo?.severity || null,
                render: (severity: string, row: any) => {
                return <AlertSeverity initialValue={severity} data={row} name={'severity'} onChange={onChange} onRevert={onRevert} />
                }
            },
            // {
            //     title: <div onClick={(e) => e.stopPropagation()}>{t("Severity")}</div>,
            //     dataIndex: "severity",
            //     key: "severity",
            //     ellipsis: true,
            //     filters: severityList,
            //     filteredValue: filteredInfo?.severity || null,
            //     render: (severity: string, row: any) => {
            //         return <AlertSeverity initialValue={severity} data={row} name={'severity'} onChange={onChange} onRevert={onRevert} />
            //     }
            // },

            {
                title: <div className="headText" onClick={(e) => e.stopPropagation()}>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.EventId)}>
                    <p className="tableHeadingFixed">{t(jsonData.EventId)}</p>
                </Tooltip>,
                <CustomFilter 
                    optionList={eventIdList} 
                    selectedData={selectedDeviceStateUpdate}
                    selectedItems={selectedDeviceStates?.eventId??[]}
                    allSelectedText={t(jsonData.AllStates)}
                    placeholder={t(jsonData.SelectState)}
                    optionListClass="state-optionList-eventId"
                    wrapperClassName="state-multiselect"
                    coulmnName="eventId"
                    filterName={t(jsonData.eventIdFilter)}
                />
                 </div>,
                dataIndex: "eventId",
                key: "eventId",
                ellipsis: true,
                filteredValue: filteredInfo?.eventId || null,
                sortOrder: sortedInfo?.columnKey === 'eventId' && sortedInfo?.order,
                sorter: (a: any, b: any) => null,
            },
            {
                title:<div className="headText" onClick={(e) => e.stopPropagation()}>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Event)}>
                   <p className="tableHeadingFixed"> {t(jsonData.Event)} </p>
                </Tooltip>
                    <CustomFilter 
                    optionList={eventList} 
                    selectedData={selectedDeviceStateUpdate}
                    selectedItems={selectedDeviceStates?.event??[]}
                    allSelectedText={t(jsonData.AllStates)}
                    placeholder={t(jsonData.SelectState)}
                    optionListClass="state-optionList-event"
                    wrapperClassName="state-multiselect"
                    coulmnName="event"
                    filterName={t(jsonData.eventFilter)}
                    allowSearch={true}
                />
                </div>,
                dataIndex: "event",
                key: "event",
                ellipsis: true,
                filteredValue: filteredInfo?.event || null,
            },
            {
                title:    <div className="headText" onClick={(e) => e.stopPropagation()}>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Type)}>
                    <p>{t(jsonData.Type)}</p>
                </Tooltip>
                <CustomFilter 
                    optionList={type} 
                    selectedData={selectedDeviceStateUpdate}
                    selectedItems={selectedDeviceStates?.type??[]}
                    allSelectedText={t(jsonData.AllStates)}
                    placeholder={t(jsonData.SelectState)}
                    optionListClass="state-optionList-type"
                    wrapperClassName="state-multiselect"
                    coulmnName="type"
                    filterName={t(jsonData.typeFilter)}
                /></div>,
                dataIndex: "type",
                key: "type",
                ellipsis: true,
                filteredValue: filteredInfo?.type || null,
                render: (text) => {
                    if (text === "Notification") {
                      return  (<Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.notification)}><img className='alertNotifyIcon' src={NotificationBellIcon} alt="notification" /></Tooltip>);
                    } else if (text === 'Alert') {
                      return (<Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Alert)}><img className='alertNotifyIcon' src={alertSettings} alt="alert"/></Tooltip>);
                    } else {
                      return null;
                    }
                  }
            },
            {
                title: <div className="headText"  onClick={(e) => e.stopPropagation()}>
                 <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title= {t(jsonData.Category)}>
                   <p> {t(jsonData.Category)}</p>
                   </Tooltip>
                <CustomFilter 
                    optionList={categoryList} 
                    selectedData={selectedDeviceStateUpdate}
                    selectedItems={selectedDeviceStates?.category??[]}
                    allSelectedText={t(jsonData.AllStates)}
                    placeholder={t(jsonData.SelectState)}
                    optionListClass="state-optionList-category"
                    wrapperClassName="state-multiselect"
                    coulmnName="category"
                    filterName={t(jsonData.categoryFilter)}
                />
                </div>,
                dataIndex: "category",
                key: "category",
                ellipsis: true,
                filteredValue: filteredInfo?.category || null,
            },
            {
                title:  <div className='headText alertConfigHeading' onClick={(e) => e.stopPropagation()}>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Active)}>
                    <p>{t(jsonData.Active)}</p>
                    </Tooltip>
                    <SquareCheckbox checkBoxName={t(jsonData.activeAllSelectCheckbox)} onChange={handleActivateCheckbox} className={'header-checkbox'} isChecked={activateAll ?? false}/>
                </div>,
                dataIndex: "isActive",
                key: "isActive",
                render: (isActive: any, row: any) => {
                    return (
                        <AlertToggle
                            initialValue={isActive ?? false}
                            data={row}
                            name={'isActive'}
                            onChange={onChange}
                            onRevert={onRevert}
                            onSelect={onActivate}
                        />
                    )
                }
            },

            {
                title: <div className='headText alertConfigHeading' onClick={(e) => e.stopPropagation()}>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.SMS)}>
                   <p> {t(jsonData.SMS)}</p>
                   </Tooltip>
                    <SquareCheckbox checkBoxName={t(jsonData.smsAllSelectCheckbox)} onChange={handleSmsNotifyCheckbox} className={'header-checkbox'} isChecked={selectAllSms ?? false}/>
                </div>,
                dataIndex: "isSMSNotify",
                key: "isSMSNotify",
                render: (isSMSNotify: any, row: any) => {
                    return (
                        <SmsCheckbox
                            initialValue={isSMSNotify ?? false}
                            data={row}
                            onChange={onChange}
                            name='isSMSNotify'
                            onRevert={onRevert}
                            onSelectCheckbox={onSmsSelect}
                        />
                    )
                }
            },

            {
                title: <div className='headText alertConfigHeading' onClick={(e) => e.stopPropagation()}>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Email)}>
                   <p  className="tableHeadingFixed">{t(jsonData.Email)} </p>
                </Tooltip>
                    <SquareCheckbox checkBoxName={t(jsonData.emailAllSelectCheckbox)} onChange={handleEmailNotifyCheckbox} className={'header-checkbox'} isChecked={selectAllEmail ?? false}/>
                </div>,
                dataIndex: "isEmailNotify",
                key: "isEmailNotify",
                render: (isEmailNotify: any, row: any) => {
                    return (
                        <EmailCheckbox
                            initialValue={isEmailNotify ?? false}
                            data={row}
                            onChange={onChange}
                            name='isEmailNotify'
                            onRevert={onRevert}
                            onSelectCheckbox={onEmailSelect}
                        />
                    )
                }
            },

            {
                title: <div className=' headText alertConfigHeading' onClick={(e) => e.stopPropagation()}>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title= {t(jsonData.Banner)}>
                  <p> {t(jsonData.Banner)}</p>
                  </Tooltip>
                    <SquareCheckbox checkBoxName={t(jsonData.bannerAllSelectCheckbox)} onChange={handleBannerNotifyCheckbox} className={'header-checkbox'} isChecked={selectAllBanner ?? false}/>
                </div>,
                dataIndex: "isPopUpBanner",
                key: "isPopUpBanner",
                render: (isPopUpBanner: any, row: any) => {
                    return (
                        <AdminCheckbox
                        checkboxValue={t(jsonData.Banner)}
                            initialValue={isPopUpBanner ?? false}
                            data={row}
                            onChange={onChange}
                            name='isPopUpBanner'
                            onRevert={onRevert}
                            onSelectCheckbox={onBannerSelect}
                        />
                    )
                }
            },

            {
                title: <div className=' headText alertConfigHeading' onClick={(e) => e.stopPropagation()}>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Administrator)}>
                  <p>  {t(jsonData.Administrator)} </p>
                </Tooltip>
                    <SquareCheckbox checkBoxName={t(jsonData.administratorAllSelectCheckbox)} onChange={handleAdminNotifyCheckbox} className={'header-checkbox'} isChecked={selectAllAdmin ?? false}/>
                </div>,
                dataIndex: "isOnlyAdminNotify",
                key: "isOnlyAdminNotify",
                render: (isOnlyAdminNotify: any, row: any) => {
                    return (
                        <AdminCheckbox
                            checkboxValue={t(jsonData.Administrator)}
                            initialValue={isOnlyAdminNotify ?? false}
                            data={row}
                            onChange={onChange}
                            name='isOnlyAdminNotify'
                            onRevert={onRevert}
                            onSelectCheckbox={onAdminSelect}
                        />
                    )
                }
            },
        ]

        if(appUser?.locations[0].role.name === UserRole?.PLATFORM_ADMIN){
            columns.splice(4, 0, 
                {
                    title: <div className='headText'onClick={(e) => e.stopPropagation()}> 
                    <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title= {t(jsonData.ClearedBy)}>
                       <p  className="tableHeadingFixed"> {t(jsonData.ClearedBy)}</p>
                       </Tooltip>
                    <CustomFilter 
                        optionList={clearingEventList} 
                        selectedData={selectedDeviceStateUpdate}
                        selectedItems={selectedDeviceStates?.clearingEvent??[]}
                        allSelectedText={t(jsonData.AllStates)}
                        placeholder={t(jsonData.SelectState)}
                        optionListClass="state-optionList-clearingEvent"
                        wrapperClassName="state-multiselect"
                        coulmnName="clearingEvent"
                        filterName={t(jsonData.clearedByFilter)}
                    />
                    </div>,
                    dataIndex: "clearingEvent",
                    key: "clearingEvent",
                    ellipsis: true,
                    filteredValue: filteredInfo?.clearingEvent || null,
                    render: (clearingEvent: any, row: any) => {
                        return (
                            <AlertInput initialValue={clearingEvent} data={row} name={'clearingEvent'} onChange={onChange} onRevert={onRevert} save={canSaveChanges}/>
                        )
                    }
                },
            )
        }

        return columns
    }

    // if clearing id is invalid , we are checking changes are saved or not. 
    const canSaveChanges = (save: boolean) => {
        setInValid(!save)
    }

    useEffect(() => {
        if (changed) {

            if (alertConfigUpdatedInfo[changed?.row?.eventId]) {
                dispatch(setAlertConfigUpdatedInfo({ ...alertConfigUpdatedInfo[changed?.row?.eventId], ...changed?.keyUpdated }))
            }
            else {
                dispatch(setAlertConfigUpdatedInfo({ ...changed?.row, ...changed?.keyUpdated }))
            }
            setChanged(null)
        }
    }, [changed])

    const onChange = (row: any, keyUpdated: any) => {
        setChanged({ row, keyUpdated })
        dispatch(setAlertConfigChanges({ data: row, updated: keyUpdated }))
    }

    const onRevert = (rowId: any, name: any) => {
        dispatch(deleteFromAlertConfigChanges({ id: rowId, name: name }))
    }


    const handleActivateCheckbox = () => {
        if (activateAll !== null) {
            setActivateAll(!activateAll)
            // if (!activateAll === true) {
            //     changeOrRevert(true, AlertColumn.ACTIVE)
            //     setTotalActivated(filteredData.map((x: any) => x?.eventId))
            // }
            // else {
                changeOrRevert(false, AlertColumn.ACTIVE)
                setTotalActivated([])
            // }
        }
        else {
            changeOrRevert(true, AlertColumn.ACTIVE)
            setActivateAll(true)
            setTotalActivated(filteredData.map((x: any) => x?.eventId))
        }
    }

    const handleSmsNotifyCheckbox = () => {
        if (selectAllSms !== null) {
            setSelectAllSms(!selectAllSms)
            // if (!selectAllSms === true) {
            //     changeOrRevert(true, AlertColumn.SMS)
            //     setTotalSmsNotified(filteredData.map((x: any) => x?.eventId))
            // }
            // else {
                changeOrRevert(false, AlertColumn.SMS)
                setTotalSmsNotified([])
            // }
        }
        else {
            changeOrRevert(true, AlertColumn.SMS)
            setSelectAllSms(true)
            setTotalSmsNotified(filteredData.map((x: any) => x?.eventId))
        }
    }

    const handleEmailNotifyCheckbox = () => {
        if (selectAllEmail !== null) {
            setSelectAllEmail(!selectAllEmail)
            // if (!selectAllEmail === true) {
            //     changeOrRevert(true, AlertColumn.EMAIL)
            //     setTotalEmailNotified(filteredData.map((x: any) => x?.eventId))
            // }
            // else {
                changeOrRevert(false, AlertColumn.EMAIL)
                setTotalEmailNotified([])
            // }
        }
        else {
            changeOrRevert(true, AlertColumn.EMAIL)
            setSelectAllEmail(true)
            setTotalEmailNotified(filteredData.map((x: any) => x?.eventId))
        }
    }

    const handleAdminNotifyCheckbox = () => {
        if (selectAllAdmin !== null) {
            setSelectAllAdmin(!selectAllAdmin)
            // if (!selectAllAdmin === true) {
            //     changeOrRevert(true, AlertColumn.ADMINISTRATOR)
            //     setTotalAdminNotified(filteredData.map((x: any) => x?.eventId))
            // }
            // else {
                changeOrRevert(false, AlertColumn.ADMINISTRATOR)
                setTotalAdminNotified([])
            // }
        }
        else {
            changeOrRevert(true, AlertColumn.ADMINISTRATOR)
            setSelectAllAdmin(true)
            setTotalAdminNotified(filteredData.map((x: any) => x?.eventId))
        }
    }

    const handleBannerNotifyCheckbox = () => {
        if (selectAllBanner !== null) {
            setSelectAllBanner(!selectAllBanner)
            // if (!selectAllBanner === true) {
            //     changeOrRevert(true, AlertColumn.BANNER)
            //     setTotalBannerNotified(filteredData.map((x: any) => x?.eventId))
            // }
            // else {
                changeOrRevert(false, AlertColumn.BANNER)
                setTotalBannerNotified([])
            // }
        }
        else {
            changeOrRevert(true, AlertColumn.BANNER)
            setSelectAllBanner(true)
            setTotalBannerNotified(filteredData.map((x: any) => x?.eventId))
        }
    }

    const changeOrRevert = (value: boolean, name: string) => {

        for(let row of filteredData){
            if(row[name] !== value){
                if (alertConfigUpdatedInfo[row?.eventId]) {
                    dispatch(setAlertConfigUpdatedInfo({ ...alertConfigUpdatedInfo[row?.eventId], [name]: value }))
                }
                else {
                    dispatch(setAlertConfigUpdatedInfo({ ...row, [name]: value }))
                }
                dispatch(setAlertConfigChanges({ data: row, updated: { [name]: value} }))
            }
            else if(alertConfigChanges[row?.eventId] && alertConfigChanges[row?.eventId][name] !== null && alertConfigChanges[row?.eventId][name] !== undefined){
                dispatch(deleteFromAlertConfigChanges({ id: row?.eventId, name: name }))
            }
        }

    }


    /*************************** on selecting active toggle switch **************************/
    const onActivate = (rowId: any, value: boolean) => {
        if (value) {
            setTotalActivated([...totalActivated, rowId])
        }
        else {
            setTotalActivated(totalActivated.filter((x: any) => x !== rowId))
        }
    }

    useEffect(() => {
        if (alertConfigList?.length) {
            if (totalActivated?.length === filteredData?.length && filteredData?.length) {
                setActivateAll(true)
            }
            else {
                setActivateAll(null)
            }
        }
    }, [totalActivated])



    /*************************** on selecting sms checkbox **************************/
    const onSmsSelect = (rowId: any, value: boolean) => { 
        if (value) {
            setTotalSmsNotified([...totalSmsNotified, rowId])
        }
        else {
            setTotalSmsNotified(totalSmsNotified.filter((x: any) => x !== rowId))
        }
    }

    useEffect(() => {
        if (alertConfigList?.length) {
            if (totalSmsNotified?.length === filteredData?.length && filteredData?.length) {
                setSelectAllSms(true)
            }
            else {
                setSelectAllSms(null)
            }
        }
    }, [totalSmsNotified])



    /*************************** on selecting email checkbox **************************/
    const onEmailSelect = (rowId: any, value: boolean) => { 
        if (value) {
            setTotalEmailNotified([...totalEmailNotified, rowId])
        }
        else {
            setTotalEmailNotified(totalEmailNotified.filter((x: any) => x !== rowId))
        }
    }

    useEffect(() => {
        if (alertConfigList?.length) {
            if (totalEmailNotified?.length === filteredData?.length && filteredData?.length) {
                setSelectAllEmail(true)
            }
            else {
                setSelectAllEmail(null)
            }
        }
    }, [totalEmailNotified])



      /*************************** on selecting admin checkbox **************************/
      const onAdminSelect = (rowId: any, value: boolean) => { 
        if (value) {
            setTotalAdminNotified([...totalAdminNotified, rowId])
        }
        else {
            setTotalAdminNotified(totalAdminNotified.filter((x: any) => x !== rowId))
        }
    }

    useEffect(() => {
        if (alertConfigList?.length) {
            if (totalAdminNotified?.length === filteredData?.length && filteredData?.length) {
                setSelectAllAdmin(true)
            }
            else {
                setSelectAllAdmin(null)
            }
        }
    }, [totalAdminNotified])




     /*************************** on selecting banner checkbox **************************/
     const onBannerSelect = (rowId: any, value: boolean) => { 
        if (value) {
            setTotalBannerNotified([...totalBannerNotified, rowId])
        }
        else {
            setTotalBannerNotified(totalBannerNotified.filter((x: any) => x !== rowId))
        }
    }

    useEffect(() => {
        if (alertConfigList?.length) {
            if (totalBannerNotified?.length === filteredData?.length && filteredData?.length) {
                setSelectAllBanner(true)
            }
            else {
                setSelectAllBanner(null)
            }
        }
    }, [totalBannerNotified])

    

    // to calculate scroll height
    useEffect(() => {
        if (!alertConfigFormState?.loading && !loading) {

            //table height
            const tableHeight = getHeight('.ant-table-body')

            //to calculate table footer height
            //   const compContentHeight = getHeight('.ant-layout-content')
            //   const bottomGap = compContentHeight - (screenHeight * 0.8)
            //   const pagination_div = document.querySelector('.alert-pagination')?.clientHeight
            //   const footerHeight = (totalAlerts / ALERT_DEFAULT_LIMIT) > 1 ? pagination_div + bottomGap : 0;

            //to calculate final height of the table
            const calculatedHeight = tableHeight
            const scrollH = parseInt(calculatedHeight.toFixed(0));
            !isNaN(scrollH) && setScrollHeight(scrollH)
        }
    }, [screenHeight, screenWidth, alertConfigFormState?.loading, loading])

    const onScroll = (event: any) => {
        if (event.target.scrollTop && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            setScrolledToEnd(true);
        }
    }

    const loadMore = () => {
        if (tableData?.length !== filteredData?.length && scrolledToEnd) {
            setScrolledToEnd(false);
            fetchData(currentIndex).then(async (newData: any) => {
                setTableData(tableData.concat(newData));
                setCurrentIndex(currentIndex + ALERT_CONFIG_PAGE_SIZE);
            }
            );
        }
    }

    const fetchData = async (startIndex: number) => {
        const t = filteredData.slice(startIndex, startIndex + ALERT_CONFIG_PAGE_SIZE);
        return t;
    }

    const handleSubmit = () => {
        let parameters = {
            organisationId: getOrgId(),
            hierarchyId: null,
            roleId: null,
            createdBy: appUser?.id,
            configs: []
        }
        let data = Object.values(alertConfigUpdatedInfo).map((item: any) => {
            let config: any = {
                severity: item?.severity,
                isSMSNotify: item?.isSMSNotify ?? false,
                isEmailNotify: item?.isEmailNotify ?? false,
                isOnlyAdminNotify: item.isOnlyAdminNotify ?? false,
                isPopUpBanner: item?.isPopUpBanner ?? false,
                // isBellNotify : item?.isBellNotify ?? false,
                isWebNotify: false,
                isActive: item?.isActive ?? false,
                isArchived: item?.isArchived ?? false,
                isDisabled: item?.isDisabled ?? false,
                clearingEvent: item?.clearingEvent
            }
            if (item?.id) {
                config.id = item?.id
            }
            if (item?.alertTypeId) {
                config.alertTypeId = item?.alertTypeId
            }
            else {
                config.eventId = item?.eventId
            }
            return config
        })
        parameters.configs = data
        setSaveInit(true);
        setTimeout(() => {
            dispatch(updateAlertConfig(parameters))
            dispatch(setIsDirty(false))
        }, 200);
    }

    useEffect(() => {
        if (updateAlertConfigFormState?.isSuccess) {
            dispatch(clearAlertConfigChanges())
            dispatch(clearAlertConfigUpdatedInfo())
            AppService.showToast(t(Messages.ALERT_SETTINGS_UPDATED));
            if (applyChanges && goTo) {
                history.push(urlModification(goTo));
                dispatch(warnUnsave({ calledBy: null, isWarnUnsaveOpen: false }));
            }
            else {
                setLoading(true)
                setTableData([])
                setCurrentIndex(0)
                let params = {
                    organizationId : getOrgId(),
                    isActive : alertType === AlertTypeDropdown.ALL_VALUE ? null : alertType 
                }
                dispatch(fetchAlertConfig(params))
            }
        }
        if (updateAlertConfigFormState?.isError) {
            AppService.showToast(`${t(jsonData.SomeErrorOccured)}.`, true);
            isWarnUnsaveOpen && dispatch(warnUnsave({ calledBy: null, isWarnUnsaveOpen: false }));
        }
    }, [updateAlertConfigFormState])

    // to apply selected filters and sorters
    const filterSorting = (pagination, filters, sorter, extra) => {
        setFilteredInfo(filteredInfo)
        setSortedInfo(sorter);
    }

    const getSortedData = async (sortValue?: any, filterData?: any) => {
        const keyValue = sortValue?.column?.key
        const order = sortValue?.order

        if (order === "ascend") {
            if (keyValue !== "eventId") {
                let ascendingData = filterData?.sort(function (a: any, b: any) {
                    return a[keyValue]?.localeCompare(b[keyValue]);
                });
                return ascendingData;
            }
            else {
                return [...filterData]?.sort((a, b) => a[keyValue] - b[keyValue])
            }
        }
        else if (order === "descend") {
            if (keyValue !== "eventId") {
                let descendingData = filterData?.sort(function (a: any, b: any) {
                    return b[keyValue]?.localeCompare(a[keyValue]);
                });
                return descendingData;
            }
            else {
                return [...filterData]?.sort((a, b) => b[keyValue] - a[keyValue])
            }
        } else if (keyValue === undefined || order === undefined) {
            return filterData
        }
    }

    const updateTotalSelected = (data: any) => {
        let activeList = []
        let smsSelectedList = []
        let emailSelectedList = []
        let adminSelectedList = []
        let bannerSelectedList = []

        if(data?.length){
            for(let row of data){

                // push elements if isActive 
                if (alertConfigChanges[row?.eventId] && alertConfigChanges[row?.eventId]?.isActive !== null && alertConfigChanges[row?.eventId]?.isActive !== undefined) {
                    if (alertConfigChanges[row?.eventId]?.isActive) {
                        activeList.push(row?.eventId)
                    }
                }
                else if (row?.isActive === true) {
                    activeList.push(row?.eventId)
                }

                // push elements if isSMSNotify 
                if (alertConfigChanges[row?.eventId] && alertConfigChanges[row?.eventId]?.isSMSNotify !== null && alertConfigChanges[row?.eventId]?.isSMSNotify !== undefined) {
                    if (alertConfigChanges[row?.eventId]?.isSMSNotify) {
                        smsSelectedList.push(row?.eventId)
                    }
                }
                else if (row?.isSMSNotify === true) {
                    smsSelectedList.push(row?.eventId)
                }

                // push elements if isEmailNotify 
                if (alertConfigChanges[row?.eventId] && alertConfigChanges[row?.eventId]?.isEmailNotify !== null && alertConfigChanges[row?.eventId]?.isEmailNotify !== undefined) {
                    if (alertConfigChanges[row?.eventId]?.isEmailNotify) {
                        emailSelectedList.push(row?.eventId)
                    }
                }
                else if (row?.isEmailNotify === true) {
                    emailSelectedList.push(row?.eventId)
                }

                //push elements if isOnlyAdminNotify
                if (alertConfigChanges[row?.eventId] && alertConfigChanges[row?.eventId]?.isOnlyAdminNotify !== null && alertConfigChanges[row?.eventId]?.isOnlyAdminNotify !== undefined) {
                    if (alertConfigChanges[row?.eventId]?.isOnlyAdminNotify) {
                        adminSelectedList.push(row?.eventId)
                    }
                }
                else if (row?.isOnlyAdminNotify === true) {
                    adminSelectedList.push(row?.eventId)
                }

                 //push elements if isPopUpBanner
                 if (alertConfigChanges[row?.eventId] && alertConfigChanges[row?.eventId]?.isPopUpBanner !== null && alertConfigChanges[row?.eventId]?.isPopUpBanner !== undefined) {
                    if (alertConfigChanges[row?.eventId]?.isPopUpBanner) {
                        bannerSelectedList.push(row?.eventId)
                    }
                }
                else if (row?.isPopUpBanner === true) {
                    bannerSelectedList.push(row?.eventId)
                }

            }

            setTotalActivated(activeList)
            setTotalSmsNotified(smsSelectedList)
            setTotalEmailNotified(emailSelectedList)
            setTotalAdminNotified(adminSelectedList)
            setTotalBannerNotified(bannerSelectedList)
        }

    }

    const onChangeAlertType = (value) => {
        setAlertType(value)
        let params = {
            organizationId : getOrgId(),
            isActive : value === AlertTypeDropdown.ALL_VALUE ? null : value
        }
        setLoading(true)
        setTableData([])
        setCurrentIndex(0)
        setActivateAll(null)
        setSelectAllSms(null)
        setSelectAllEmail(null)
        setSelectAllBanner(null)
        dispatch(fetchAlertConfig(params))
      }

    useEffect(() => {
        if (Object.keys(alertConfigChanges)?.length) {
            !isDirty && dispatch(setIsDirty(true))
        }
        else {
            isDirty && dispatch(setIsDirty(false))
        }
    }, [alertConfigChanges])

    const handleKeyPress = () => {
        if(isDirty) {
            handleSubmit()
        }
    }

    return (
        <>
    {(appUser.locations[0].role.name === UserRole.LIBRARY_FRONT_DESK || appUser.locations[0].role.name === UserRole.BIBLIOTHECA_USER)?

      <Redirect to={urlModification(AppRoutes.USER_DASHBOARD)} />
      :
        <>
            <WarnUnsavedChanges
                ignorePrompt={saveInit}
                navigateOnCancel={true}
                title={t(UnsavedChangesWarnModal.TITLE)}
                content={t(UnsavedChangesWarnModal.CONTENT)}
                cancelBtnText={t(UnsavedChangesWarnModal.DISCARD_BTN_TEXT)}
                confirmBtnText={t(UnsavedChangesWarnModal.CONFIRM_BTN_TEXT)}
                isDirty={isDirty ? true : false}
                onConfirm={handleSubmit}
            />
            <div className="ml-23 pl-vw alertSetting-component">
                <Row className='alertSetting-header'>
                    <Col span={12} lg={15} md={24} >
                        <Col  className="alertSetting-heading">
                            {t(jsonData.AlertSettings)}
                        </Col>
                    </Col>
                    <Col md={10} lg={0} xl={0}></Col>
                    <Col span={5} md={4} lg={3} xl={1} className='alertSetting-saveBtn'>
                        <Dropdown
                            ariaLabel={alertType}
                            dropdownAlign={{ offset: [-125, 4] }}
                            className="filterDropDown alertPageDropdown"
                            onChange={onChangeAlertType}
                            value={alertType}
                            bordered={false}
                            optionValue={translateOptions(filterOptions.alertType,t,'text')}
                            tabIndex={0}
                            aria-label={""}
                        />
                        </Col>
                       <Col span={7} md={7} lg={6} xl={4} className='alertSetting-saveBtn'>
                        <Form.Item >
                            {
                                updateAlertConfigFormState?.loading ?
                                    <Button disabled type="smallBlack">
                                        <div className="loaderButton"><span className="spinner"></span></div>{t(jsonData.Saving)}
                                    </Button>
                                    :
                                    isDirty ? (
                                        <Tooltip overlayClassName='dashBoardTooltip'  placement="topLeft" title={t(jsonData.ApplyChanges)}>
                                        <div>
                                        <Button
                                            type="smallBlack"
                                            onClick={() => {
                                                handleSubmit()
                                            }}
                                            htmlType="submit"
                                            disabled={inValid}
                                        >
                                            {t(jsonData.ApplyChanges)}
                                        </Button>
                                        </div>
                                        </Tooltip>
                                    ) : (
                                        <Button type="smallBlack" disabled htmlType="submit">
                                            {t(jsonData.AllSaved)}
                                        </Button>
                                    )}
                        </Form.Item>
                    </Col>
                </Row>
 
                    <AntdInfinityTable
                        tableClassName='alertAntdCustom'
                        dataSource={tableData}
                        onScroll={onScroll}
                        scroll={
                            {
                                y: scrollHeight,
                                scrollToFirstRowOnChange: true
                            }}
                        locale={!alertConfigFormState?.loading && !loading && !tableData?.length && { emptyText: t(jsonData.NoDataFound) }}
                        columns={alertConfigColumns()}
                        onChange={(pagination, filters, sorter, extra) => {
                            filterSorting(pagination, filters, sorter, extra)
                        }}
                        pagination={false}
                        onFetch={loadMore}
                        pageSize={ALERT_CONFIG_DEFAULT_LIMIT}
                        loading={alertConfigFormState?.loading || loading}
                        loadingIndicator={<div className='loaderWrapper'><div className="loaderBody"><span className="spinner"></span><span className="loadingText">{t(jsonData.Loading)}.</span></div></div>}
                    />
             
            </div>
        </>
    }</>
    )
}