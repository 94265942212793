import { Subject } from "rxjs";

const toastSubject = new Subject();

export const AppService = {
  showToast: (msg: string, isError?: boolean) =>
    toastSubject.next({
      msg: msg,
      isError: isError
    }),
  listenToaster: () => toastSubject.asObservable(),
};
