/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AppRoutes, HeaderType, LeftMenuType } from "../../../../constants/enums";
import { MainLayoutService } from "../../../../services/ui/mainLayoutService";
import { LibrarySideBarItem, SideBarItems } from "../../../../constants/sideBarConstants";
import { Col, Row, Tooltip } from "antd";
import { useHistory } from "react-router";
import AlertList from "../../alerts/alertList/alertList";
import { useDispatch, useSelector } from "react-redux";
import { AlertSelector } from "../../../../../redux/reducers/alert/alertReducer";
import { fetchAlertByOrgId } from "../../../../../redux/actions/alert/alertAction";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { cameFromOrganisation, getClientTimeZoneOffset, getOrganizationDateAndTime, translateGraphTextDashbord, urlModification } from "../../../../../utility/appUtil";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { onPage, sidebarNavigate } from "../../../../../redux/actions/app/appAction";
import { getActiveAlerts } from "../../../../../utility/alertUtils";
import './style.less'
import { ResponsiveLine } from '@nivo/line'
import { ResponsivePie } from '@nivo/pie'
import {
  insightsSelector,
  clearState,
  clearReportDataFormSate,
  clearReports,
  setViewDataValues
} from '../../../../../redux/reducers/insights/insightsReducer'
import moment from 'moment-timezone'
import { ReportRowData } from '../../../../models/insights/reports'
import {
  TooltipXDIv,
  TooltipYDiv,
  TooltipMainDiv,
  InsightDivider
} from '../../insights/insights.styles'
import {
  fetchMyReport,
  fetchReportDataByReportId,
  fetchReportsForUser,
} from '../../../../../redux/actions/insights/insightsAction'
import { locationListSelector } from '../../../../../redux/reducers/location/locationListReducer'
import { KioskUsageReport, Pages, Status_Type, TimePattern, jsonData } from "../../../../constants/constants";
import Loader from "../../../stateless/common/spinner";
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper";
import { formatScaling } from "../../../../../utility/insightUtils";
import { fetchDevicesHealthByOrgId } from "../../../../../redux/actions/device/deviceAction";
import { clearDevicesHealth, clearDevicesHealthFormState, DeviceSelector, setDeviceFilter, setDeviceHealthParams } from "../../../../../redux/reducers/device/deviceReducer";
import { getDeviceStateColor, getDeviceStateColorDashboard } from "../../../../../utility/deviceUtils";
import { getStatusTypeEllipse, getStatusTypeEllipseDashboard } from "../../../../../utility/utils"
import { DeviceHealthBody } from "../../device/deviceHealth/deviceHealthHeader/index.styles";
import { MainDashboard, StyledChartDashboard } from "./dashboard.Styles";
import { useTranslation } from 'react-i18next';
import { EditIcon } from "../../../../images";

export const UserDashboard = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { alerts, totalAlerts } = useSelector(AlertSelector);
  const { appUser } = useSelector(userStateSelector);
  const { selectedOrganisation } = useSelector(appSelector);
  const [orgId, setOrgId] = useState('');
  const [activeAlerts, setActiveAlerts] = useState<any[]>(alerts);
  const { reports, reportData, formState, myReports } = useSelector(insightsSelector)
  const { devicesHealth, devicesHealthFormstate, deviceHealthParams } = useSelector(DeviceSelector)
  const { locations } = useSelector(locationListSelector)
  const [chartData, setChartData] = useState<any>([])
  const [pieChart, setPieChart] = useState<any[]>([])
  const [selectedReport, setSelectedReport] = useState(null)
  const startDate = getOrganizationDateAndTime(moment().utc().subtract(1, 'week').add(1, 'day').format())
  const endDate = getOrganizationDateAndTime(moment().utc().format())
  const [yScale, setYScale] = useState<number[]>()
  const [yMax, setYMax] = useState<number | 'auto'>('auto')
  const chartTimeFormat = 'YYYY-MM-DD ' + TimePattern.HH_MM
  const [screenWidth, setScreenWidth] = useState(document.body?.clientWidth)
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation()

  const getPieChartStatus = (data) => {
    return data.map(function (key: any) {
      return (
        <DeviceHealthBody className="showPieColourCode">
          <img src={getStatusTypeEllipseDashboard(key.id, t)} alt="" /> <span className="pieLabels" >{t(key.label)} </span> <span className="pieValues">{key.value} </span>
        </DeviceHealthBody>
      )
    })
  }

  const onNameClick = (data: any) => {
    dispatch(setViewDataValues(data))
    history.push(urlModification(AppRoutes.MY_SAVED_INSIGHT.split(':')[0] + data.id), { Viewable: true });

  }

  useEffect(() => {
    const node = document.body
    const resizeObserver = new ResizeObserver((entries) => {
      setScreenWidth(node?.clientWidth)
    });
    resizeObserver.observe(node)
  }, [])

  // get active alerts
  useEffect(() => {
    getActiveAlerts(alerts).then((data: any) => {
      setActiveAlerts(data);
    })
      .catch((err: any) => {
        console.log('Failed to get Active alerts');
      })
  }, [alerts])

  useEffect(() => {
    setOrgId(getOrgId());
  }, [selectedOrganisation])

  useEffect(() => {
    dispatch(onPage({ onComponent: Pages.JUNTO_PORTAL }));
    MainLayoutService.renderHeader(HeaderType.JUNTO_PORTAL);
    MainLayoutService.renderSideBar(
      LeftMenuType.JUNTO_BLACK,
      LibrarySideBarItem.DASHBOARD
    );
    dispatch(sidebarNavigate(SideBarItems.DASHBOARD))
    if (orgId !== '') {
      dispatch(fetchAlertByOrgId({
        organizationId: getOrgId(),
        startDate: getOrganizationDateAndTime(moment().utc().subtract(1, 'month').add(1, 'day').startOf('day').format()),
        endDate: getOrganizationDateAndTime(moment().utc().format()),
        severity: [Status_Type.ERROR, Status_Type.WARNING]
      }))
      setLoading(true);
      dispatch(fetchReportsForUser({ organisationId: getOrgId() }))
      dispatch(fetchDevicesHealthByOrgId({ organizationId: getOrgId(), isDashboard: true }))
      dispatch(fetchMyReport({ organisationId: getOrgId(), userId: appUser.id }))
    }
    return () => {
      dispatch(clearState());
      dispatch(clearReports());
      dispatch(clearReportDataFormSate());
      dispatch(clearDevicesHealth());
      dispatch(clearDevicesHealthFormState());
    }
  }, [orgId]);

  useEffect(() => {
    if (devicesHealthFormstate?.isSuccess || devicesHealthFormstate?.isError) {
      dispatch(clearDevicesHealthFormState())
    }
  }, [devicesHealthFormstate])

  const getOrgId = () => {
    let orgId = appUser?.organization?.id as string;
    if (cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  }

  // get Locations
  const getLocations = () => {
    let arrLocations: any[] = []
    for (let location of locations) {
      let loc: any = location
      arrLocations.push({
        label: loc.name,
        value: loc.id.toString(),
      })
    }
    return arrLocations
  }
  const [selectedLocation, setSelectedLocation] = useState(getLocations())
  useEffect(() => {
    setSelectedLocation(getLocations())
  }, [locations])
  // selectors for reports
  const [format, setFormat] = useState<{
    x?: {
      format: string
      scale: {
        precision: any
        format: string
        tickValue: any
        min?: any
        max?: any
      }
    }
    y?: {}
  }>({})

  // format lookup for week interval
  const formatLookup = {
    day: {
      x: {
        format: 'time:%d %b',
        scale: {
          precision: 'day',
          format: '%A ',
          tickValue: 'every day',
          min: moment(startDate).startOf('day').format(chartTimeFormat),
          max: moment(endDate).endOf('day').format(chartTimeFormat),
        },
      },
      y: {},
    }
  }
  // if fetch reports request complete then fetch reportData
  useEffect(() => {
    if (reports?.length) {
      const reportId = reports.filter((x) => x?.name?.toLowerCase() === KioskUsageReport.Report)
      setSelectedReport(reportId[0] ?? reports[0])

      dispatch(
        fetchReportDataByReportId({
          reportId: reportId[0]?.id ?? reports[0]?.id,
          organisationId: getOrgId(),
          locations:
            selectedLocation.length < locations.length ? selectedLocation : [],
          startDate: startDate,
          endDate: endDate,
          interval: 'day',
          calculateKioskUsage: true
        }),
      )
    }
  }, [reports])
  useEffect(() => {
    setFormat(formatLookup[reportData.interval])
    let ceil =
      reportData?.rows && reportData && reportData?.rows?.length > 0
        ? Math.ceil(
          reportData?.rows
            ?.map((item) => item?.data)
            .sort((a, b) => a - b)
            .pop() / 15,
        )
        : 1
    ceil = ceil || 1
    let max = ceil * 15
    setYScale([0, 1 * 5 * ceil, 2 * 5 * ceil, 3 * 5 * ceil])
    setYMax(max)
    setChartData([
      {
        id: KioskUsageReport.Id,
        data:
          reportData && reportData?.rows
            ? reportData?.rows?.map((item: ReportRowData) => {
              return {
                x: moment(item.date).utcOffset(getClientTimeZoneOffset(startDate)).format(chartTimeFormat),
                y: item.data,
              }
            })
            : [],
      },
    ])
  }, [reportData])


  const getPieChartData = (result: any) => {
    var error = 0
    var warning = 0
    var good = 0
    var uncertain = 0
    var unmonitored = 0
    if (result?.length) {
      for (let i of result) {
        switch (i.statusType) {
          case Status_Type.ERROR:
            error++
            break;
          case Status_Type.WARNING:
            warning++
            break;

          case Status_Type.GOOD:
            good++
            break;

          case Status_Type.UNCERTAIN:
            uncertain++
            break;

          case Status_Type.UNMONITORED:
            unmonitored++
            break;
        }
      }
    }

    const pieChartData = [
      {
        "id": (t(Status_Type.ERROR)),
        "label": Status_Type.ERROR,
        "value": error,
      },
      {
        "id": (t(Status_Type.WARNING)),
        "label": Status_Type.WARNING,
        "value": warning,
      },
      {
        "id": (t(Status_Type.GOOD)),
        "label": Status_Type.GOOD,
        "value": good,
      },
      {
        "id": (t(Status_Type.UNCERTAIN)),
        "label": Status_Type.UNCERTAIN,
        "value": uncertain,
      },
      {
        "id": (t(Status_Type.UNMONITORED)),
        "label": Status_Type.UNMONITORED,
        "value": unmonitored,
      }
    ]
    setPieChart(pieChartData)
  }

  const goToMyInsight = () => {
    history.push(urlModification(AppRoutes.MY_INSIGHTS))
    dispatch(sidebarNavigate(SideBarItems.INSIGHTS))
  }

  useEffect(() => {
    getPieChartData(devicesHealth)
  }, [devicesHealth])

  const getPayloads = (e: any) => {
    let severity = e?.data?.id;
    let allFilters = [
      "off",
      "error",
      "warning",
      "info",
      "maintenance",
      "good",
      "uncertain",
    ];
    let FilterPayload = severity ? [severity] : allFilters;
    let ApiPayload = severity ? [severity] : [];
    return [FilterPayload, ApiPayload];
  };

  const goToDeviceHealth = (e: any) => {
    const [FilterPayload, ApiPayload] = getPayloads(e);

    // to set filters
    dispatch(setDeviceFilter({ deviceState: FilterPayload }));

    // to call api at first time on page load
    dispatch(setDeviceHealthParams({ states: ApiPayload }));

    history.push(urlModification(AppRoutes.DEVICE_HEALTH));
  };

  const goToInsights = (e: any) => {
    e.stopPropagation();
    history.push(urlModification(AppRoutes.INSIGHTS)
    );
  }

  const sortAlert = (value: any) => {
    const sortBy = value.split("-")[0];
    const sortOrder = value.split("-")[1];
    dispatch(fetchAlertByOrgId({
      organizationId: getOrgId(),
      startDate: getOrganizationDateAndTime(moment().utc().subtract(1, 'month').add(1, 'day').startOf('day').format()),
      endDate: getOrganizationDateAndTime(moment().utc().format()),
      severity: [Status_Type.ERROR, Status_Type.WARNING],
      sortBy: sortBy,
      sortOrder: sortOrder
    }))
  }

  useEffect(() => {
    if (devicesHealthFormstate?.isSuccess || devicesHealthFormstate?.isError) {
      setLoading(false);
    }
  }, [devicesHealthFormstate]);

  return (
    <Loader loading={formState?.loading || loading }>
      <CompWrapper otherClasses="ant-row">
        <MainDashboard span={16} xs={13} md={13} sm={13} lg={16}>
          <Row gutter={[2, 27]} justify="center" className="dashboardBox">
            <Col span={24} className="upperBox">
              <Col tabIndex={0} aria-label={""} role={""} className="dashboardPieImageGrid">
                <h3>{t(jsonData.DeviceHealth)}</h3>
                <div className="graphStyle">
                  <div className="mainDesktopLegendDiv">
                    <Col className="legendsPie">
                      {getPieChartStatus(pieChart)}
                    </Col>
                  </div>
                  <Col tabIndex={0} aria-label={t(jsonData.pressEnterToSeeTheDeviceHealthGraphData)} role={"link"} onKeyPress={goToDeviceHealth} md={24} lg={14} className="pieGraph">
                    <div className="pieGraph">
                      {pieChart.some(element => element?.value !== 0) ?
                        <ResponsivePie
                          onClick={(event) => goToDeviceHealth(event)}
                          data={pieChart}
                          margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
                          innerRadius={.45}
                          padAngle={0}
                          cornerRadius={3}
                          activeOuterRadiusOffset={8}
                          borderWidth={1}
                          borderColor={{
                            from: 'color',
                            modifiers: [
                              [
                                'darker',
                                0.2
                              ]
                            ]
                          }}
                          enableArcLinkLabels={false}
                          arcLinkLabelsSkipAngle={20}
                          arcLinkLabelsThickness={2}
                          arcLabelsSkipAngle={10}
                          arcLinkLabelsDiagonalLength={8}
                          arcLinkLabelsStraightLength={8}
                          arcLabelsTextColor={"#ffffff"}
                          colors={(item) => {
                            return getDeviceStateColorDashboard(t(item?.id?.toString()), t)
                          }
                          }
                        />
                        :
                        <p className="pieTextMessage">{t(jsonData.NoDeviceFound)}</p>
                      }
                    </div>
                  </Col>
                </div>
                <div className="mainLegendDiv">
                  <Col className="legendsPie">
                    {getPieChartStatus(pieChart)}
                  </Col>
                </div>
              </Col>
              <Col tabIndex={0} aria-label={t(jsonData.myInsights)} role={""} className="dashboardMyInsightGrid">
                <Row className="myinsightHeaderBox">
                  <Col>
                    <h3>{t(jsonData.MyInsights)}</h3>
                  </Col>
                  <Col tabIndex={0} aria-label={t(jsonData.editYourInsight)} role={"button"} onKeyPress={goToMyInsight}>
                    <Tooltip overlayClassName='dashBoardTooltip' placement="bottomLeft" title={t(jsonData.Edit)}>
                      <img className="editMyInsight" src={EditIcon} alt={'Edit'} onClick={goToMyInsight}></img>
                    </Tooltip>
                  </Col>
                  </Row>
                  {myReports?.some(element => element?.value !== 0) ? 
                <div className="insightNameList">
                  {myReports?.map((data: any) => 
                  <Tooltip overlayClassName='dashBoardTooltip' placement="bottomLeft" title={data?.name}>
                    <div tabIndex={0} aria-label={""} onKeyPress={() => onNameClick(data)}  role="link"  className="insightListItems" onClick={() => onNameClick(data)}>{data?.name}</div> 
                  <div className="insightDivider">
                    <InsightDivider
                      key={data.id}
                    />
                  </div>
                  </Tooltip>
                  
                  )}
                </div> : 
                <div className="noReportData">{t(jsonData.noReportFound)}</div> 
                  }
              </Col>
            </Col>
            <Col span={24} className="lowerBox">
              <StyledChartDashboard tabIndex={0} aria-label={t(jsonData.kioskUsage)} role={""} className="dashboardGraph">
                <Row className="dashboardChart">
                  <Col>
                    <h3>{t(`${KioskUsageReport.Title}`)}</h3>
                  </Col>
                  <Col>
                    <span className="graphSubHeading">
                      {t(jsonData.Total)}:{' '}
                    </span>
                    <b className="graphCount">
                      {chartData[0]?.data?.reduce((acc, c) => {
                        return acc + c.y
                      }, 0) || 0}
                    </b>
                  </Col>
                </Row>
                <div className="insightGraphDashboard"
                >
                </div>
                <div tabIndex={0} aria-label={t(jsonData.pressEnterToSeeTheKioskUsageGraphData)} role={"link"} onKeyPress={(event: any) => goToInsights(event)} className="lineGraph dashGraph" onClick={(event: any) => goToInsights(event)}>
                  <ResponsiveLine
                    data={translateGraphTextDashbord(chartData, t, 'id')}
                    margin={{ top: 10, right: 64, bottom: 50, left: 30 }}
                    xScale={{
                      type: 'time',
                      format: '%Y-%m-%d %H:%M',
                      min: 'auto',
                      max: 'auto',
                      useUTC: false,

                      precision: format?.x?.scale?.precision ?? 'day',
                    }}
                    xFormat={format?.x?.format ?? 'time:%A'}
                    yScale={{
                      type: 'linear',
                      stacked: true,
                      max: yMax || 'auto',
                    }}
                    axisLeft={{
                      legend: '',
                      legendOffset: -32,
                      legendPosition: 'end',
                      tickValues: yScale || 4,
                      tickSize: 0,
                      tickPadding: 15,
                      format:
                        function (num) {
                          return formatScaling(num)
                        }
                    }}
                    gridYValues={4}
                    enableGridX={false}
                    axisBottom={{
                      format: screenWidth < 1000 ? '%a' : format?.x?.scale?.format ?? '%A',
                      tickValues: format?.x?.scale?.tickValue ?? 'every day',
                      tickSize: 0,
                      tickPadding: 10,
                      ticksPosition: 'after',
                    }}
                    curve={'monotoneX'}
                    enableCrosshair={false}
                    enableSlices={'x'}
                    colors={{ scheme: 'category10' }}
                    pointSize={3}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={3}
                    pointBorderColor={{ from: 'serieColor' }}
                    useMesh={true}
                    legends={[{
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 95,
                      translateY: 10,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)'
                    }]}
                    sliceTooltip={({ slice }) => {
                      let point = slice.points[0]
                      return (
                        <TooltipMainDiv>
                          <TooltipXDIv>{point.data.xFormatted}</TooltipXDIv>
                          <TooltipYDiv>{point.data.y}</TooltipYDiv>
                          <TooltipXDIv className="dashboardTooltip">
                            {t(KioskUsageReport.Title)}
                          </TooltipXDIv>
                        </TooltipMainDiv>
                      )
                    }}
                  ></ResponsiveLine>
                </div>
              </StyledChartDashboard>
            </Col>
          </Row>
        </MainDashboard>
        <Col span={8} lg={8} sm={11} md={11} xs={11}>
          <fieldset tabIndex={0} aria-label={t(jsonData.alerts)} role={""} className="dashboardAlertList">
            <AlertList alerts={activeAlerts} totalAlerts={totalAlerts} sortAlert={sortAlert} />
          </fieldset>
        </Col>
      </CompWrapper>
    </Loader>
  );
};