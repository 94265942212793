/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Row, Col, Form, Switch, Card, Tooltip } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../../../bit_components/common/button";
import {
  userStateSelector,
  resetAccountSettingsFormState,
} from "../../../../../redux/reducers/user/userReducer";
import { useDispatch } from "react-redux";
import {
  saveAccountSettings,
} from "../../../../../redux/actions/user/userProfileAction";
import WarnUnsavedChanges from "../../common/warnUnsavedChanges";
import {
  clearSavedFormValues,
  commonSelector,
} from "../../../../../redux/reducers/common/commonReducer";
import { Pages, UnsavedChangesWarnModal, jsonData } from "../../../../constants/constants";
import { AppService } from "../../../../services/ui/appService";
import { SavedFormValue } from "../../../../models/common/savedFormValue";
import {
  saveFormValues,
  saveDirtyFormValues,
} from "../../../../../redux/actions/common/commonAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { onPage, sidebarNavigate, warnUnsave } from "../../../../../redux/actions/app/appAction";
import { getAppHeaderType, urlModification } from "../../../../../utility/appUtil";
import { HeaderType } from "../../../../constants/enums";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { useHistory } from "react-router-dom";
import { Messages } from "../../../../constants/messages";
import { postEmail, postSms } from "../../../../../redux/actions/notifications/notificationsAction";
import { clearPostEmailFormState, clearPostSmsFormState, NotificationSelector } from "../../../../../redux/reducers/notifications/notificationsReducer";
import { useTranslation } from 'react-i18next';

export const NotificationsSettings = (props: any) => {
  const { t } = useTranslation()
  const setInitialForm = (appUser: any) => {
    return {
      // playSound: appUser?.info?.playSound || false,
      popUpBanner: appUser?.info?.popUpBanner || false,
      sms: appUser?.info?.sms || false,
      email: appUser?.info?.email || false,
    };
  };
  const history = useHistory();
  const {applyChanges, goTo, isWarnUnsaveOpen} = useSelector(appSelector);
  const dispatch = useDispatch();
  const formName = "accountSettings";

  const { appUser, accountSettingsFormState, user } = useSelector(
    userStateSelector
  );
  const [notificationForm, setNotificationForm] = useState<any>(
    setInitialForm(appUser)
  );
  const [form] = Form.useForm();
  const [saveInit, setSaveInit] = useState<any>(false);
  const [showAplyBtn, setShowAplyBtn] = useState<any>(false);
  const { savedFormValues, isDirty } = useSelector(commonSelector);
  const { postEmailFormState, postSmsFormState } = useSelector(NotificationSelector)
  const [smsvalid,setSmsValid] = useState<boolean>(true)

  let initialValues: SavedFormValue[] = [
    // {
    //   field: "playSound",
    //   value: appUser?.info?.playSound,
    // },    
    {
      field: "popUpBanner",
      value: appUser?.info?.popUpBanner,
    },
    {
      field: "sms",
      value: appUser?.info?.sms,
    },
    {
      field: "email",
      value: appUser?.info?.email,
    },
  ];

  useEffect(() => {
    dispatch(sidebarNavigate(SideBarItems.ALERTS));
    if(appUser && getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL){
      dispatch(onPage({onComponent : Pages.ACCOUNT_SETTINGS}));
    } else {
      dispatch(onPage({onComponent : Pages.BIBLIOTHECA_ACCOUNT_SETTINGS}));
    }
  }, []);

  useEffect(() => {
    dispatch(clearSavedFormValues());
    dispatch(clearPostEmailFormState());
    dispatch(clearPostSmsFormState())
  }, []);

  useEffect(() => {
    if (user) {
    }
    return () => {
      dispatch(clearSavedFormValues());
      dispatch(clearPostEmailFormState());
      dispatch(clearPostSmsFormState())
    };
  }, [user]);

  useEffect(() => {
    dispatch(saveFormValues(initialValues));
    setSaveInit(false);
    if(appUser.phone.length < 1) {
      setSmsValid(false)
    } else {
      setSmsValid(true)
    }
  }, [appUser]);

  useEffect(() => {
    if (accountSettingsFormState?.isSuccess) {
      AppService.showToast(t(jsonData.NotificationSettingsUpdatedSuccessfully));
      dispatch(resetAccountSettingsFormState());
      if(applyChanges && goTo){
        history.push(urlModification(goTo));
        dispatch(warnUnsave({calledBy: null, isWarnUnsaveOpen: false}));
      }
    }

    if (accountSettingsFormState?.isError) {
      AppService.showToast(`${t(jsonData.SomeErrorOccured)}.`, true);
      dispatch(resetAccountSettingsFormState());
      isWarnUnsaveOpen && dispatch(warnUnsave({calledBy: null, isWarnUnsaveOpen: false}));
    }
  }, [accountSettingsFormState]);

  const onSwitchToggle = (e: any, target: any) => {
    setNotificationForm({ ...notificationForm, [target]: e });
    onFormChangedCallback({
      target: {
        id: target,
        type: "text",
        value: e,
      },
    });
  };

  // find newValue key inside arrays if exist return true otherwise false
  const checkKeyPresenceInArray = key => savedFormValues.some(obj => Object.keys(obj).includes(key));

  useEffect(() => {
    let key = checkKeyPresenceInArray("newValue");
    if (key !== false) {
      setShowAplyBtn(true);
    } else {
      setShowAplyBtn(false);
    }
  }, [savedFormValues]);

  const onFormChangedCallback = (e: any) => {
    if (savedFormValues) {
      if (e.target) {
        dispatch(
          saveDirtyFormValues({
            target: e.target,
            savedFormValues: savedFormValues,
          })
        );
      }
    }
  };

  const onFinish = (e: any) => {
    const user: any = {
      id: appUser?.id ?? '',
      info: { ...appUser?.info, ...notificationForm },
    };
    setSaveInit(true);
    setTimeout(() => {
      dispatch(saveAccountSettings(user));
      dispatch(clearSavedFormValues());
    }, 200);
  };

  useEffect(() => {
    if (postEmailFormState?.isSuccess) {
      AppService.showToast(t(jsonData.EmailSentEmailIdSuccessfully));
    }
    if (postEmailFormState?.isError) {
      AppService.showToast(`${t(jsonData.SomeErrorOccured)}.`, true);
    }
  }, [postEmailFormState]);

  useEffect(() => {
    if (postSmsFormState?.isSuccess) {
      AppService.showToast(t(jsonData.SMSSenttoYourPhone));
    }
    if (postSmsFormState?.isError) {
      AppService.showToast(`${t(jsonData.SomeErrorOccured)}.`, true);
    }
  }, [postSmsFormState]);

  const onSentEmail = () => {
    const data: any = {
      toEmail: appUser.email,
      subject: "Email Service Verification",
      msgBody: "Email service working fine"
    }
    dispatch(postEmail(data))
  }

  const onSentSms = () => {
    const data: any = {
      toSMS: [appUser.phone],
      msgBody: "SMS service working fine",
      organisationId: appUser?.organization?.id
    }
    dispatch(postSms(data))
  }

  return (
    <>
      <WarnUnsavedChanges
        ignorePrompt={saveInit}
        navigateOnCancel={true}
        title={t(UnsavedChangesWarnModal.TITLE)}
        content={t(UnsavedChangesWarnModal.CONTENT)}
        cancelBtnText={t(UnsavedChangesWarnModal.DISCARD_BTN_TEXT)}
        confirmBtnText={t(UnsavedChangesWarnModal.CONFIRM_BTN_TEXT)}
        isDirty={isDirty ? isDirty : false}
        onConfirm={form.submit}
      />
      <div className="ml-23 mt-25">
        <Row gutter={[33, 33]} className="accHed pl-vw">
          <Col xs={8} sm={8} md={12} lg={9} xl={7} xxl={7} className="mb-10">
            <span className="accSettHedLeft">{t(jsonData.AlertSettings)}</span>
          </Col>
          <Col xs={10} sm={10} md={4} lg={8} xl={12} xxl={13}></Col>
          <Col xs={6} sm={6} md={8} lg={7} xl={5} xxl={4} className="noti-Padding">
            <div className="accSettHed">
              <Form.Item className="mb-0 w-100 hedMinHeight">
                {!showAplyBtn ? (
                    <Button type="smallBlack" disabled htmlType="submit">
                      <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Saved)}>
                    {t(jsonData.Saved)}
                      </Tooltip>
                  </Button>
                ) : (
                  <Button
                    type="smallBlack"
                    onClick={() => {
                      form.submit();
                    }}
                    htmlType="submit"
                  >
                     <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.ApplyChanges)}>
                    {t(jsonData.ApplyChanges)}
                    </Tooltip>
                  </Button>
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row className="mt-47 pl-vw">
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              name={formName}
              requiredMark={false}
              onFinish={onFinish}
            >
              <Row>
                <Col span={5} sm={20} md={14} lg={10} xl={8}>
                  <label htmlFor="" className="notificationSettingsLabel">
                    {t(jsonData.WebPortalNotifications)}
                  </label>
                  {/* <Card className="switchCard mt-8">
                        <Form.Item
                          name="playSound"
                          initialValue={notificationForm.playSound}
                        >
                        <Switch
                          className="notiSwitch"
                          checked={notificationForm.playSound}
                          size="small"
                            onChange={(e) => {
                            onSwitchToggle(e, "playSound");
                          }}
                          ></Switch>
                          <label htmlFor="" className="switchCardLabel">
                            Play Sound
                        </label>
                      </Form.Item>
                    </Card> */}
                </Col>
              </Row>
              <Row className="mt-10">
                <Col span={5} sm={16} md={14} lg={10} xl={8}>
                  <Card className="switchCard">
                    <Form.Item
                      name="popUpBanner"
                      initialValue={notificationForm.popUpBanner}
                    >
                      <div className="notifydivStyle">
                        <Switch
                          aria-label={t(jsonData.PopUpBanner)}
                          className="notiSwitch"
                          checked={notificationForm.popUpBanner}
                          size="small"
                          onChange={(e) => {
                            onSwitchToggle(e, "popUpBanner");
                          }}
                        ></Switch>
                        <label htmlFor="" className="switchCardLabel">
                          {t(jsonData.PopUpBanner)}
                        </label>
                        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Test)}>
                          <Col md={6} lg={5} xl={4} className="notificationFormItemStyle">
                            <Button
                              type="smallBlack"
                              disabled={!appUser?.info?.popUpBanner || !notificationForm.popUpBanner}
                              onClick={() => {
                                AppService.showToast(t(Messages.POP_UP_BANNER));
                              }}
                              htmlType="button"
                            >
                              {t(jsonData.Test)}
                            </Button>
                          </Col>
                        </Tooltip>
                      </div>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-37">
                <Col span={5} sm={16} md={14} lg={10} xl={8}>
                  <label htmlFor="" className="notificationSettingsLabel">
                    {t(jsonData.SendAlertVia)}:
                  </label>
                  <Card className="switchCard mt-8">
                    <Form.Item name="sms" initialValue={notificationForm.sms}>
                      <div className="notifydivStyle">
                        <Switch
                          className="notiSwitch"
                          aria-label= {t(jsonData.SMS)}
                          checked={notificationForm.sms}
                          size="small"
                          onChange={(e) => {
                            onSwitchToggle(e, "sms");
                          }}
                        ></Switch>
                        <label htmlFor="" className="switchCardLabel">
                          {t(jsonData.SMS)}
                        </label>
                        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Test)}>
                          <Col md={6} lg={5} xl={4} className="notificationFormItemStyle">
                            <Button
                              type="smallBlack"
                              disabled={!appUser?.info?.sms || !notificationForm.sms || !smsvalid}
                              onClick={onSentSms}
                              htmlType="button"
                            >
                              {t(jsonData.Test)}
                            </Button>
                          </Col>
                        </Tooltip>
                      </div>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-10">
                <Col span={5} sm={16} md={14} lg={10} xl={8}>
                  <Card className="switchCard">
                    <Form.Item
                      name="email"
                      initialValue={notificationForm.email}
                    >
                      <div className="notifydivStyle">
                        <Switch
                          aria-label= {t(jsonData.Email)}
                          className="notiSwitch"
                          checked={notificationForm.email}
                          size="small"
                          onChange={(e) => {
                            onSwitchToggle(e, "email");
                          }}
                        ></Switch>
                        <label htmlFor="" className="switchCardLabel">
                          {t(jsonData.Email)}
                        </label>
                        <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.Test)}>
                          <Col md={6} lg={5} xl={4} className="notificationFormItemStyle">
                              <Button
                                type="smallBlack"
                                onClick={onSentEmail}
                                disabled={!appUser?.info?.email || !notificationForm.email}
                                htmlType="button"
                              >
                                {t(jsonData.Test)}
                              </Button>
                          </Col>
                        </Tooltip>
                      </div>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};
