/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
import { Form } from "antd";
import {Button} from "../../../../../bit_components/common/button";
import { Row, Col, Tooltip } from "antd";
import {Custominput} from "../../../../../bit_components/common/custominput";
import {Dropdown}  from "../../../../../bit_components/common/dropdown";
import { ProfilePicture } from "../../../stateless/user/profile/profilePicture";
import { AvatarColorList, UserActionType, ResetPwdWarnModal, Pages, jsonData } from "../../../../constants/constants";
import { useSelector } from "react-redux";
import { userStateSelector, resetAccountSettingsFormState, clearUser, clearUserActions } from "../../../../../redux/reducers/user/userReducer";
import { AccountLockIcon, resetPassRedIcon } from "../../../../images";
import "./account.less";
import { useState } from "react";
import { useHistory } from "react-router";
import { concatNames,convertPhoneNumberToUserFormat,convertUserFormatToPhoneNumber,getAppHeaderType, urlModification } from "../../../../../utility/appUtil";
import { parseName } from "humanparser";
import { useDispatch } from "react-redux";
import { saveAccountSettings } from "../../../../../redux/actions/user/userProfileAction";
import { UnsavedChangesWarnModal } from "../../../../constants/constants";
import WarnUnsavedChanges from "../../common/warnUnsavedChanges";
import {
  clearSavedFormValues,
  commonSelector,
} from "../../../../../redux/reducers/common/commonReducer";
import {
  saveDirtyFormValues,
  saveFormValues,
} from "../../../../../redux/actions/common/commonAction";
import { AppService } from "../../../../services/ui/appService";
import { AppRoutes,UserRole } from "../../../../constants/enums";
import User from "../../../../models/user/user";
import WarnModal from "../../../stateless/common/warnModal";
import { UserAuthInput } from "../../../../models/user/userAuthInput";
import { sendForgotPwdLink } from "../../../../../redux/actions/user/forgotPwdAction";
import { SavedFormValue } from "../../../../models/common/savedFormValue";
import { logout } from "../../../../../redux/reducers/app/appReducers";
import { sidebarNavigate, onPage, warnUnsave } from "../../../../../redux/actions/app/appAction";
import { SideBarItems } from "../../../../constants/sideBarConstants";
import { HeaderType } from "../../../../constants/enums";
import { appSelector } from "../../../../../redux/reducers/app/appReducers";
import { CompWrapper } from "../../../stateless/common/compWrapper/compWrapper";
import { AppError } from "../../../stateless/common/appError";
import { CheckboxLabel, CheckBoxWrapper } from "./account.styles";
import {Checkbox} from "../../../../../bit_components/common/checkbox";
import { useTranslation } from 'react-i18next';
import secureLocalStorage from "react-secure-storage";

export const AccountSettings = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const {applyChanges, goTo, isWarnUnsaveOpen} = useSelector(appSelector);
  const formName = 'accountSettings';
  const [form] = Form.useForm();
  const history = useHistory();
  const [change, setChange] = useState(false);
  const [saveInit, setSaveInit] = useState(false);
  const { savedFormValues, isDirty } = useSelector(commonSelector);
  const { appUser, accountSettingsFormState, forgotPwdRequest, user, errorMsg } = useSelector(userStateSelector);
  const [inputField, setInputField] = useState({
    fullname: appUser ? concatNames(appUser?.firstName, appUser?.lastName, appUser?.middleName, appUser?.title) : "",
    email: appUser ? appUser.email : "",
    phone: appUser ? appUser.phone : "",
    userName: appUser ? appUser.userName : ""
  });
  const [showWarn, setShowWarn] = useState(false);
  const [warnCancelBtn, setWarnCancelBtn] = useState("");
  const [warnConfirmBtn, setWarnConfirmBtn] = useState("");
  const [warnTitle, setWarnTitle] = useState("");
  const [warnContent, setWarnContent] = useState("");
  const [warnConfirmType, setWarnConfirmType] = useState<any>();
  const [userActionValue, setUserActionValue] = useState<any>();
  const [showError, setShowError] = useState(null);
  const selectedLocations = appUser?.locations?appUser?.locations:[];
  const [phoneNumber, setPhoneNumber] = useState<any>()
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState<boolean>(false)

  let initialValues: SavedFormValue[] = [
    {
      field: "fullname",
      value: concatNames(appUser?.firstName, appUser?.lastName, appUser?.middleName, appUser?.title),
    },
    {
      field: "email",
      value: appUser?.email,
    },
    {
      field: "phone",
      value: appUser?.phone,
    },
    {
      field: "profileUrl",
      value: appUser?.profileUrl,
    },
    {
      field: "userName",
      value: appUser?.userName
    }
  ];

  useEffect(() => {
    dispatch(sidebarNavigate(SideBarItems.ACCOUNT));
    if(appUser && getAppHeaderType(appUser) === HeaderType.JUNTO_PORTAL){
      dispatch(onPage({onComponent : Pages.ACCOUNT_SETTINGS}));
    } else {
      dispatch(onPage({onComponent : Pages.BIBLIOTHECA_ACCOUNT_SETTINGS}));
    }
  }, []);

  const scrollToTop = () => {
    const node = document.querySelector<HTMLElement>(".scrollContent");
    node?.scrollTo(0, 0);
  }

  useEffect(() => {
    if(showError && showError !== ''){
      scrollToTop();
    }
  },[showError])

  useEffect(()=>{
    dispatch(clearSavedFormValues());
  },[]);

  useEffect(() => {
    if (accountSettingsFormState?.isSuccess) {
      AppService.showToast(t(jsonData.AccountSettingsUpdatedSuccessfully));
      dispatch(resetAccountSettingsFormState());
      dispatch(clearSavedFormValues());
      if(applyChanges && goTo){
        history.push(urlModification(goTo));
        dispatch(warnUnsave({calledBy: null, isWarnUnsaveOpen: false}));
      }
    }

    if (accountSettingsFormState?.isError) {
      setShowError(errorMsg)
      AppService.showToast(`${t(jsonData.SomeErrorOccured)}.`, true);
      dispatch(resetAccountSettingsFormState());
      isWarnUnsaveOpen && dispatch(warnUnsave({calledBy: null, isWarnUnsaveOpen: false}));
    }
  }, [accountSettingsFormState]);

  useEffect(() => {
    if (appUser && forgotPwdRequest && userActionValue !== null) {
      setShowWarn(false);
      user?.errorMsg ? AppService.showToast(user?.errorMsg, true) : AppService.showToast( t(jsonData.EmailSentSuccess));
      dispatch(clearUser());
      dispatch(clearUserActions());
      dispatch(logout());
      setUserActionValue(null);
    }
  }, [forgotPwdRequest]);

  useEffect(() => {
    if(user){
      // dispatch(selectUser(user));
    }
    return () => {
      // dispatch(clearProfileSavedState());
      dispatch(clearSavedFormValues());
    };
  }, [user]);

  useEffect(() => {
    dispatch(saveFormValues(initialValues));
    form.setFieldsValue({
      fullname: appUser ? concatNames(appUser?.firstName, appUser?.lastName, appUser?.middleName, appUser?.title) : "",
      email: appUser ? appUser.email : "",
      // phone: convertPhoneNumberToUserFormat(appUser?.phone),
      phone: appUser?.phone,
    });
    setSaveInit(false);
  }, [appUser]);

  const inputsHandler = (e: any) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
    setChange(true);
  };
  const onFinish = (data: any) => {
    //debugger;
    setShowError(null);
    if (1) {
      const parsedName = parseName(data.fullname);
      let lastName = parsedName?.lastName
      let suffix = parsedName?.suffix
      lastName = suffix? (lastName? lastName+" "+suffix : suffix) : lastName

      const user: any = {
        id: appUser?.id ?? '',
        email: data.email,
        title: parsedName.salutation ? parsedName.salutation : "",
        firstName: parsedName.firstName ? parsedName.firstName : "",
        middleName: parsedName.middleName ? parsedName.middleName : "",
        lastName: lastName ? lastName : "",
        phone: phoneNumber,
        profileUrl: appUser?.profileUrl ?? "",
      };
      setSaveInit(true);
      setTimeout(() => {
        dispatch(saveAccountSettings(user));
      }, 200);
    }
  };

  const onFormChangedCallback = (e: any) => {
    if (savedFormValues) {
      if (e.target) {
        //debugger;
        dispatch(
          saveDirtyFormValues({
            target: e.target,
            savedFormValues: savedFormValues,
          })
        );
      }
    }
  };

  const onPhoneNumberChange = (event: any) => {
    // let data = convertUserFormatToPhoneNumber(event?.target?.value)
    // if (data?.isValidNumber) {
    //   setPhoneNumber(data?.formatNumber)
    //   setInvalidPhoneNumber(false)
    // } else {
    //   setInvalidPhoneNumber(true)
    // }
    setPhoneNumber(event?.target?.value)
  }
  const changePassword = (e: any) => {
    history.push(AppRoutes.CHANGE_PASSWORD);
  };

  const onWarnModalCallback = () => {
    //debugger;
    if (warnConfirmType === UserActionType.CHANGE_PWD) {
      onResetPwd();
    }
  };

  const onResetPwd = () => {
    if (userActionValue && userActionValue.id && userActionValue.id !=='') {
      const data: UserAuthInput = {
        id: '',
        email: userActionValue.email,
        password: ""
      }
      dispatch(sendForgotPwdLink(data));
    }
  };

  const onResetPwdCallback = (user: User | null) => {
    if (user && user.id && user.id !=='') {
      setUserActionValue(user);
      setWarnConfirmType(UserActionType.CHANGE_PWD);
    }
    setWarnCancelBtn(t(ResetPwdWarnModal.CANCEL_BTN_TEXT));
    setWarnConfirmBtn(t(ResetPwdWarnModal.CONFIRM_BTN_TEXT));
    setWarnTitle(t(ResetPwdWarnModal.USER_TITLE));
    setWarnContent(t(ResetPwdWarnModal.USER_CONTENT));
    setShowWarn(true);
  };

  const getRoles = () => {
    let arrRole: any[] = [];
    const roles:any[] = [appUser?.locations[0]?.role];
    if (roles && roles.length) {
      arrRole = arrRole.concat(
        roles.map((x: any) => {
          return { text: x.name, value: x.id };
        })
      );
    }
    return arrRole;
  };

  const url:any = secureLocalStorage.getItem('accountSettingUrl')
  const EditDetails = () => {
    window.open(url)
  }

  return (
    <>

      <WarnModal
        cancelButton={warnCancelBtn}
        confirmButton={warnConfirmBtn}
        primaryText={warnTitle}
        secondaryText={warnContent}
        isModalVisible={showWarn}
        cancelCallback={() => {
          setUserActionValue(null);
          setShowWarn(false);
        }}
        confirmCallback={onWarnModalCallback}
      />

      <WarnUnsavedChanges
        ignorePrompt={saveInit}
        navigateOnCancel={true}
        title={t(UnsavedChangesWarnModal.TITLE)}
        content={t(UnsavedChangesWarnModal.CONTENT)}
        cancelBtnText={t(UnsavedChangesWarnModal.DISCARD_BTN_TEXT)}
        confirmBtnText={t(UnsavedChangesWarnModal.CONFIRM_BTN_TEXT)}
        isDirty={isDirty ? isDirty : false}
        onConfirm ={form.submit}
      />

      <div className="ml-23 mt-25">
        <Row gutter={[33, 0]} className="accHed pl-vw accHed-margin">
          <Col xs={8} sm={8} md={16} lg={12} xl={12} xxl={12} className="mb-10">
            <span className="accSettHedLeft">
              {t(jsonData.AccountSettings)}
            </span>
          </Col>
          {/* <Col xs={8} sm={8} md={3} lg={7} xl={11} xxl={11}></Col>
          <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1} className="headRightBlock">
            <div className="accSettHedRight accountResetBtn">
              <Tooltip placement='bottomRight' title='Reset Password'>
                <span className="userProfileBtn" onClick={() => { onResetPwdCallback(appUser) }}>
                  <img
                    src={AccountLockIcon}
                    onMouseOver={e => (e.currentTarget.src = resetPassRedIcon)}
                    onMouseOut={e => (e.currentTarget.src = AccountLockIcon)}
                    alt=""
                  />
                </span>
              </Tooltip>
            </div>
          </Col> */}
          <Col xs={6} sm={6} md={7} lg={7} xl={5} xxl={4} className='headSavedBlock'>
            <div className="accSettHed">
              <Form.Item className="w-100 mb-0 hedMinHeight">
                {/* {!isDirty ? (
                  <Button type="smallBlack" disabled htmlType="submit">
                    Saved
                  </Button>
                ) : (
                  <Button type="smallBlack" onClick={() => { form.submit() }} key="submit" htmlType="submit">
                    Apply Changes
                  </Button>
                )} */}
                <Button type="smallBlack" onClick={EditDetails}>
                <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.EditmyDetails)}>
                {t(jsonData.EditmyDetails)}
                    </Tooltip>
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>

      <CompWrapper observeOn='accHed' otherClasses='accountPage'>
        <Row className="mt-48 pl-vw">
        <AppError show={showError && showError !== ''? true: false} errorMsg={showError} />
          <Col span={24}>
            <Form className="accountForm" layout="vertical" form={form} name={formName} requiredMark={true} onFinish={onFinish} onChange={onFormChangedCallback}>
              <Row align="top" className="pr-41">
                <Col md={24} lg={6}>
                  <div className="accImg">
                  <ProfilePicture src={appUser?.profileUrl} firstName={appUser?.firstName} lastName={appUser?.lastName} colors={AvatarColorList} round={true} size="240" />
                  </div>
                </Col>
                <Col className="userForm accountCol" id="inner-col" md={24}  lg={16} offset={2} >
                  <Row>
                    <Col span={11}>
                    
                       <Custominput
                            labelSubName="Username"
                            labelName="Username"
                            name="Username"
                            value={inputField.userName}
                            initialValue={inputField.userName}
                            disabled={true}
                            customLabelClass="labelName"
                            rules={[
                              {
                                required: true,
                                message: t(jsonData.userNameValidation), // TODO: Handle this while developing validation framework.
                              }
                            ]}
                          />
                      <div className="pt-30">
                      <Custominput
                        onChange={inputsHandler}
                        initialValue={appUser ? concatNames(appUser?.firstName, appUser?.lastName, appUser?.middleName, appUser?.title) : ""}
                        value={appUser ? concatNames(appUser?.firstName, appUser?.lastName, appUser?.middleName, appUser?.title) : ""}
                        labelName="fullname"
                        labelSubName={jsonData.FullName}
                        name="fullname"
                        customLabelClass="labelName"
                        disabled={true}
                        rules={[
                          {
                            required: true,
                            message: t(jsonData.PleaseInputYourFullName), // TODO: Handle this while developing validation framework.
                          },
                          {
                            max: 100,
                            message: t(jsonData.PleaseEnterNameShorterThan100Chars)
                          },
                        ]}
                      />
                      </div>
                      <div className="pt-30">
                        <Custominput
                          labelSubName={jsonData.PhoneNumber}
                          name="phone"
                          labelName="phone"
                          value={inputField.phone}
                          onChange={onPhoneNumberChange}
                          initialValue={inputField.phone}
                          disabled={true}
                          customLabelClass="labelName"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: 'Please input your Phone Number!',
                          //   },
                          //   ({ getFieldValue }) => ({
                          //     validator(_, value) {
                          //       if(!value)
                          //       return Promise.resolve()
                          //         let phoneNumber=value.split(/\s/).join('')
                          //         if((phoneNumber[0]=='+'||phoneNumber[0]=='(')&&(/[0-9\)]+$/.test(phoneNumber)))
                          //         {
                          //             if(phoneNumber?.length&& phoneNumber?.length>6)
                          //             { 
                          //               if(!invalidPhoneNumber)
                          //               {
                          //               return Promise.resolve()  
                          //               }
                          //               return Promise.reject(
                          //               new Error('Please enter a valid Phone Number'),
                          //               )
                          //             }
                          //              else  
                          //             {
                          //               if(phoneNumber?.length>=1)
                          //               return Promise.reject(
                          //               new Error('Please enter a Phone Number larger than 5 digits.'),
                          //               )  
                          //             }
                                  
                          //         }
                          //         else if(/^[0-9]+$/.test(value))
                          //         {
                          //             if(phoneNumber?.length&& phoneNumber?.length>5)
                          //             {
                          //             if(!invalidPhoneNumber){
                          //             return Promise.resolve()
                          //             }
                          //             return Promise.reject(
                          //             new Error('Please enter a valid Phone Number'),
                          //             )
                          //             }
                          //             else  {
                          //             if(phoneNumber?.length>=1)
                          //             return Promise.reject(
                          //             new Error('Please enter a Phone Number larger than 5 digits.'),
                          //             )  
                          //             }
                          //         }
                          //         else{
                          //             return Promise.reject(
                          //             new Error('Please enter a valid Phone Number')
                          //             )
                          //         }
                          //     },
                          //   }),
                          // ]}
                        />
                      </div>
                    </Col>
                    <Col span={11} offset={2}>
                      <div className={`${formName}_role ant-row ant-form-item ant-form-item-with-help`}>
                          <div className="ant-col ant-col-24 ant-form-item-label">
                            <label htmlFor={`${formName}_role`} className="ant-form-item-required accountLabel" title="Role">{t("Role")}</label>
                          </div>
                          <div className="ant-col ant-col-24 ant-form-item-control">
                            <div className="ant-form-item-control-input">
                              <div className="ant-form-item-control-input-content">
                              <Dropdown
                                disabled={true}
                                optionValue={getRoles()}
                                defaultValue={appUser?.locations[0]?.role?.id}
                              />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pt-30">
                        <Custominput
                          labelSubName={jsonData.EmailAddress}
                          labelName="email"
                          name="email"
                          value={inputField.email}
                          initialValue={inputField.email}
                          onChange={inputsHandler}
                          disabled={true}
                          customLabelClass="labelName"
                          rules={[
                            {
                              required: true,
                              type: "email",
                              message:
                                inputField.email !== ""
                                  ? t(jsonData.PleaseEnterValidEmail)
                                  : t(jsonData.PleaseInputYourEmail),
                            },
                          ]}
                        />
                        </div>
                        <div className="pt-30">
                          <div className={`${formName}_locations locations-multi-select ant-row ant-form-item ant-form-item-with-help`}>
                            <div className="ant-col ant-col-24 ant-form-item-label">
                              <label htmlFor={`${formName}_locations`} className="ant-form-item-required accountLabel" title="Locations">{t(jsonData.Locations)}</label>
                            </div>
                            <div className="scrollContent scrollStyle assignedLocationList checkboxDisabled checkBoxinput-P0">
                            {appUser?.locations[0]?.role?.name === UserRole.ORGANISATION_ADMIN ?
                              <div className='branches-wrapper-for-0 disableLocBorderColor'>
                                <div className='checkbox-label-cls-unselected'>{t("All location selected")}</div></div>:
                                <>
                              {selectedLocations.map((location: { id: string, name: string }, index: number) => (
                                <div key={`locationDiv-${location.id}`} className={`assignedLocation ${index ? 'branches-wrapper' : 'branches-wrapper-for-0'}`}>
                                  <CheckBoxWrapper >
                                    <Checkbox value={location.id} name={location.name} defaultChecked={true} id={`chkLocation-${location.id}`} key={`chkLocation-${location.id}`} disabled={true} />
                                  </CheckBoxWrapper>
                                  <CheckboxLabel className="checkboxLabel loc-ellipsis">{location.name}</CheckboxLabel>
                                </div>
                              ))}
                              </>}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CompWrapper>
      </div>
    </>
  );
};
