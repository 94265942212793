/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Upload, message, Tooltip } from "antd";
import { Button } from "../../../../../../bit_components/common/button";
import { InboxOutlined } from "@ant-design/icons";
import { DeleteIconImg } from "../../../../../images";
import { Dropdown } from "../../../../../../bit_components/common/dropdown";
import { CountryRegionData } from "react-country-region-selector";
import countriesTimezones from "countries-and-timezones";
import "./basicInfo.less";
import { useDispatch } from "react-redux";
import {
  fetchOrganisationbyID,
  updateOrganisation,
} from "../../../../../../redux/actions/organisation/fetchOrganisationDetails";
import {
  organisationDetailsSelector,
  updateState,
} from "../../../../../../redux/reducers/organisation/organisationDetailsReducer";
import { useSelector } from "react-redux";
import { Custominput } from "../../../../../../bit_components/common/custominput";
import {
  IOrganisation,
} from "../../../../../models/organisation/iOrganisation";
import { AppService } from "../../../../../services/ui/appService";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { AppRoutes } from "../../../../../constants/enums";
import { cameFromOrganisation, urlModification } from "../../../../../../utility/appUtil";
import { getPermissionFor } from "../../../../../../utility/roleUtils";
import { userStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import { Permissions } from "../../../../../constants/enums";
import { appSelector } from "../../../../../../redux/reducers/app/appReducers";
import { CompWrapper } from "../../../../stateless/common/compWrapper/compWrapper";
import { AppError } from "../../../../stateless/common/appError";
import { Messages } from "../../../../../constants/messages";
import WarnUnsavedChanges from "../../../common/warnUnsavedChanges";
import { CountryLanguageMapping, UnsavedChangesWarnModal, jsonData } from "../../../../../constants/constants";
import { saveDirtyFormValues } from "../../../../../../redux/actions/common/commonAction";
import { commonSelector, setIsDirty } from "../../../../../../redux/reducers/common/commonReducer";
import { warnUnsave } from "../../../../../../redux/actions/app/appAction";
import { useTranslation } from 'react-i18next';
import { UseGAEventTracker } from "../../../../../../utility/useGAEventTracker";

const BasicInfo = (props: any) => {
  const { t } = useTranslation()
  const { update, formState, errorMsg } = useSelector(organisationDetailsSelector);
  const { appUser } = useSelector(userStateSelector);
  const { savedFormValues, isDirty } = useSelector(commonSelector);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const location: any = useLocation();
  const { selectedOrganisation } = useSelector(appSelector);
  const {applyChanges, goTo, isWarnUnsaveOpen} = useSelector(appSelector);

  const {
    id,
    organisation: { result },
  } = props;

  const getCountriesList = () => {
    return CountryRegionData.map((country: any) => {
      return {
        text: country[0],
        value: country[0],
      };
    });
  };

  const getRegionsListByCountry = (country: any) => {
    let _country = CountryRegionData.filter((item: any) => item[0] === country);
    if (_country.length) {
      return _country[0][2].split("|").map((region) => {
        return { text: region.split("~")[0], value: region.split("~")[0] };
      });
    }
    return [];
  };

  const getTimezonesListByCountry = (country: any) => {
    let _country = CountryRegionData.filter(
      (item: any) => item[0] === country
    ).shift()?.[1];
    if (_country) {
      return [
        ...new Set(
          countriesTimezones
            .getTimezonesForCountry(_country)
            ?.filter((tz: any) => tz.aliasOf === null)
        ),
      ]?.map((tz: any) => {
        return {
          text: `${tz.name}`,
          value: `${tz.name}`,
        };
      });
    }
    return [];
  };

  let identifierFromCulture = result?.info?.culture?.split("-")

  if (identifierFromCulture) {
    identifierFromCulture = identifierFromCulture[1]
  }

  const checkIfCountyExistParam = result?.info?.countryIdentifier ? result?.info?.countryIdentifier : identifierFromCulture

 // it returens name of the country 
  const checkIfCountryExist = (target: any) => {
    return CountryRegionData.filter(
      (country: any) => country[1] === target
    ).shift()?.[0];
  };

  const checkIfCountyExist = (target: any, parent: any) => {
    let countryName = checkIfCountryExist(parent)
    if (countryName) {
      return getRegionsListByCountry(countryName).filter(
        (item: any) => item.value === target
      )?.[0]?.value;
    }
    return "";
  };

  const checkIfTimezoneExist = (target: any, parent: any) => {
    return getTimezonesListByCountry(parent)
      .filter((tz: any) => tz?.value === target)
      .shift()?.value;
  };

  const initBasicInfoForm = () => {
    /**
     * check if country does not exist in the list
     */
    let country: any = result?.postalAddress?.country;

    /**
     * check if county(region) does not exist in the list
     */
    let county: any = checkIfCountyExist(
      result?.postalAddress?.county,
      checkIfCountyExistParam
    );

    /**
     * check if timezone does not exist in the list
     */
    let timezone: any = checkIfTimezoneExist(
      result?.info?.timezone,
      checkIfCountryExist(checkIfCountyExistParam)
    );

    const getLanguage = () => {
      if(CountryLanguageMapping.hasOwnProperty(country)) {
        const supportedLanguages = Object.keys(CountryLanguageMapping[country])
        if(supportedLanguages.includes(result.info.language)){
          return result.info.language
        }
      } 
      return "English";
    }

    const getCulture = () => {
      if(CountryLanguageMapping.hasOwnProperty(country)) {
        const supportedLanguages = Object.keys(CountryLanguageMapping[country])
        if(supportedLanguages.includes(result.info.language)){
          return result.info.culture
        }
      } 
      return "en-US";
    }

    /**
     * object to be returned
     */
    return {
      id: result?.id,
      name: result?.name || "",
      shortName: result?.shortName || "",
      logo: result?.info?.logo || "",
      pa_line1: result?.postalAddress?.line1 || "",
      postcode: result?.postalAddress?.postcode || "",
      locality: result?.info?.locality || "",
      country: result?.postalAddress?.country || "",
      county: county || "",
      website: result?.info?.website,
      email: result?.info?.email,
      supportURL: result?.info?.supportURL,
      language: getLanguage(),
      timezone: result?.info?.timezone || "",
      culture: getCulture(),
    };
  };

  const [countriesList, setCountriesList] = useState<any>(getCountriesList());
  const [regionsList, setRegionsList] = useState<any>(
    getRegionsListByCountry(checkIfCountryExist(checkIfCountyExistParam))
  );
  const [timezonesList, settimezonesList] = useState<any>(
    getTimezonesListByCountry(
      checkIfCountryExist(checkIfCountyExistParam)
    )
  );
  const [languagesList, setLanguagesList] = useState<any>([]);
  const [culturesList, setCulturesList] = useState<any>([]);
  const [selectedCounty, setSelectedCounty] = useState<any>(
    checkIfCountyExist(
      result?.postalAddress?.county,
      checkIfCountyExistParam
    ) || ""
  );

  const [basicInfoForm, setBasicInfoForm] = useState<any>(initBasicInfoForm());
  const [saveInit, setSaveInit] = useState<boolean>(false);
  const [change, setChange] = useState(false);

  const getOrgId = () => {
    let orgId = appUser?.organization.id as string;
    if(cameFromOrganisation()) {
      orgId = selectedOrganisation?.id;
    }
    return orgId;
  }

  useEffect(() => {
    if (update) {
      AppService.showToast(`${result?.name} ${t(jsonData.UpdateSuccess)}`);
      setChange(false);
      setLoading(false)
      if(applyChanges && goTo){
        history.push(urlModification(goTo));
        dispatch(warnUnsave({calledBy: null, isWarnUnsaveOpen: false}));
      }
      else {
      if (location.pathname.includes(AppRoutes.GENERAL_SETTINGS)) {
        dispatch(fetchOrganisationbyID(getOrgId()))
      }
      else {
        history.push(urlModification(AppRoutes.ORGANISATION_LIST));
      }
    }
    }else
    if(formState.isError){
      if(!errorMsg){
        AppService.showToast(t(Messages.ERROR_OCCURRED), true)
      }
    }

    return () => {
      dispatch(updateState(false));
    };
  }, [update]);

  useEffect(() => {
    if(basicInfoForm.country) {
      const { country } = basicInfoForm;
      let languages = [];
      if(CountryLanguageMapping.hasOwnProperty(country)){
        languages = Object.keys(CountryLanguageMapping[country])
      } else {
        languages = Object.keys(CountryLanguageMapping.Default)
      }
      setLanguagesList(
        languages.map((language: any) => {
          return { text: language, value: language };
        })
      );
      
      if (languages.indexOf(basicInfoForm.language) === -1) {
        setBasicInfoForm({ ...basicInfoForm, language: "" });
        form.setFieldsValue({language: ""})
      }
    }
  }, [basicInfoForm.country]);

  useEffect(() => {
    if(basicInfoForm.language) {
      let culture = "";
      if(CountryLanguageMapping.hasOwnProperty(basicInfoForm.country)){
        culture = CountryLanguageMapping[basicInfoForm.country][basicInfoForm.language];
      } else {
         culture = "en-US";
      }
      setBasicInfoForm({ ...basicInfoForm, culture });     
      form.setFieldsValue({county: null,culture})
    }
  }, [basicInfoForm.language]);

  const initalVal = initBasicInfoForm();

  var comparable = (o) =>
    typeof o !== "object" || !o
      ? o
      : Object.keys(o)
        .sort()
        .reduce((c, key) => ((c[key] = comparable(o[key])), c), {});

  useEffect(() => {
    const newVal =
      JSON.stringify(comparable(basicInfoForm)) ===
      JSON.stringify(comparable(initalVal));
    if (!newVal) {
      setChange(true);
    } else {
      setChange(false);
    }
  }, [basicInfoForm]);

  const isEditAllowed = () => {
    return getPermissionFor(appUser?.permissions, Permissions.ORGANISATION);
  };

  const onValueChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, [e.target.name]: e.target.value });
  };

  const onCountryChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, country: e, county: "",  language: '', culture: ''});
    setRegionsList(getRegionsListByCountry(e));
    settimezonesList(getTimezonesListByCountry(e));
    form.setFieldsValue({county: null, language:null,culture:null})
    setChange(true);
  };

  const onLanguageChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, language: e });
    setChange(true);
  };

  const onTimezoneChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, timezone: e });
    setChange(true);
  };

  const onCultureChange = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, culture: e });
    setChange(true);
  };

  const getPayload: any = () => {
    return {
      name: "",
      shortName: "",
      description: "",
      info: {
        countryCode: "",
        culture: "",
        email: "",
        supportURL: "",
        website: "",
        timezone: "",
        logo: "",
        locality: "",
        language: "",
      },
      hierarchyTypeCode: "ORG",
      parentId: null,
      isActive: true,
      pa_line1: "",
      pa_line2: "",
      pa_line3: "",
      pa_line4: "",
      pa_landmark: "",
      pa_postcode: "",
      pa_city: "",
      pa_county: "",
      pa_country: "",
      pa_isActive: true,
      ba_sameAs_pa: true,
      ba_line1: "",
      ba_line2: "",
      ba_line3: "",
      ba_line4: "",
      ba_landmark: "",
      ba_postcode: "",
      ba_city: "",
      ba_county: "",
      ba_country: "",
      ba_isActive: true,
    };
  };

  const onFinish: any = (values: any) => {
    let payload: IOrganisation = getPayload();
    let fields = { ...basicInfoForm, country: values.country };

    payload["id"] = fields.id;
    payload["name"] = fields.name;
    payload["shortName"] = fields.shortName;
    payload["info"]["culture"] = fields.culture;
    payload["info"]["email"] = fields.email;
    payload["info"]["supportURL"] = fields.supportURL;
    payload["info"]["website"] = fields.website;
    payload["info"]["timezone"] = fields.timezone;
    payload["info"]["logo"] = fields.logo;
    payload["info"]["locality"] = fields.locality;
    payload["info"]["language"] = fields.language;
    payload["pa_line1"] = fields.pa_line1;
    payload["pa_postcode"] = fields.postcode;
    payload["pa_country"] = fields.country;
    payload["pa_county"] = fields.county;

    setSaveInit(true)
    dispatch(updateOrganisation(payload));
    dispatch(setIsDirty(false));
    setTimeout(() => {
      dispatch(setIsDirty(false))
  }, 200);
    setLoading(true)
  };

  useEffect(() => {
    if (change) {
        !isDirty && dispatch(setIsDirty(true))
    }
    else {
        isDirty && dispatch(setIsDirty(false))
    }
}, [change])

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleClose = (e: any) => {
    setBasicInfoForm({ ...basicInfoForm, logo: "" });
  };

  const handleChange = async ({ fileList }: any) => {
    setBasicInfoForm({
      ...basicInfoForm,
      logo: await getBase64(fileList?.[0]?.originFileObj),
    });
    setChange(true);
  };

  const uploadButton: any = (
    <div>
      <p className="ant-upload-drag-icon draggerIcon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t(jsonData.UploadLogo)}</p>
      <p className="ant-upload-hint customHint">{t(jsonData.ValidImageFormats)}</p>
      <p className="ant-upload-hint customHint">{t(jsonData.MaxResolution)}</p>
      <p className="ant-upload-hint customHint">{t(jsonData.MaxFileSize)}</p>
    </div>
  );

  const uploadImage: any = (
    <div>
      <img src={basicInfoForm.logo} alt=""/>
      <Tooltip title={t(jsonData.Delete)} placement="bottom">
        <img
          src={DeleteIconImg}
          onClick={handleClose}
          alt="delete"
          className="basicInfoDeleteIcon"
        />
      </Tooltip>
    </div>
  );

  const handleBeforeUpload = async (file: any, fileList: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error(t(jsonData.UploadImageFormatMsg));
      return Upload.LIST_IGNORE
    }
    const isLt2M = file.size / 1024 / 1024 < 0.8;
    if (!isLt2M) {
      message.error(t(jsonData.ImageSizeExceedsLimit));
      return Upload.LIST_IGNORE
    }
    const isValid = (await checkImageWH(file, 500, 500))
    return isValid ?true:Upload.LIST_IGNORE;
  };
  const checkImageWH = (file: any, width: number, height: number) => {
    return new Promise<boolean>(function (resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = e => {
        let src = e.target.result;
        const image: any = new Image();
        image.onload = function () {
          if ((this.width && this.width > width) || (this.height && this.height > height)) {
            message.error(
              t(jsonData.UploadImageSizeLimit)+" "+ width + " * "+  height
            )
            return false;
          } else {
            resolve(true);
          }
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  }

  const handleApplyChanges = () => {
    form.submit();
    UseGAEventTracker("Organisation Details Category", "Update Organisation Details", "Organisation Details Updated")
  }

  return (
  <>
    <WarnUnsavedChanges
      ignorePrompt={saveInit}
      navigateOnCancel={true}
      title={t(UnsavedChangesWarnModal.TITLE)}
      content={t(UnsavedChangesWarnModal.CONTENT)}
      cancelBtnText={t(UnsavedChangesWarnModal.DISCARD_BTN_TEXT)}
      confirmBtnText={t(UnsavedChangesWarnModal.CONFIRM_BTN_TEXT)}
      isDirty={change ? change : false}
      onConfirm={form.submit}
    />

    <CompWrapper name="basicInfoCover" otherClasses="basicInfoCover">
      <div className="pl-vw">
        <AppError show={formState?.isError} errorMsg={errorMsg}/>
      </div>
      <Form
        layout="vertical"
        form = {form}
        name="updateOrganisation"
        requiredMark={false}
        onFinish={onFinish}
        scrollToFirstError
      >
        <div className="apply-button" onKeyPress={handleApplyChanges}>
          <Form.Item className="mb-0">
            {loading?
              <Button disabled type="smallBlack">
                <div className="loaderButton"><span className="spinner"></span></div>{t(jsonData.Saving)}
              </Button>
              :
              !change ? (
                <Button disabled type="smallBlack">
                  {t(jsonData.Saved)}
                </Button>
              ) : (
                 <Button type="smallBlack apply-info" tabIndex={0} onClick={handleApplyChanges}>
                   <Tooltip overlayClassName='dashBoardTooltip' placement="bottom" title={t(jsonData.ApplyChanges)}>
                  {t(jsonData.ApplyChanges)}
                    </Tooltip>  
                </Button>
              )}
          </Form.Item>
        </div>
        <Row gutter={[56, 32]} className="basicInfoRow basicRowGutter">
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {/* name */}
            <Custominput
              customLabelClass="labelName"
              labelSubName={t(jsonData.OrganisationName)}
              labelName="name"
              initialValue={basicInfoForm.name}
              rules={[
                { required: true, message: t(jsonData.OrganisationNameRequired) },
              ]}
              placeholder={t(jsonData.Name)}
              name="name"
              value={basicInfoForm.name}
              onChange={onValueChange}
              disabled={true}
            ></Custominput>

            {/* shortName */}
            <Custominput
              customLabelClass="labelName formControl mt-20"
              labelSubName={t(jsonData.DisplayName)}
              labelName="shortName"
              initialValue={basicInfoForm.shortName}
              placeholder={t(jsonData.DisplayName)}
              name="shortName"
              value={basicInfoForm.shortName}
              onChange={onValueChange}
              disabled={!isEditAllowed()}
              tabIndex={0}
              ariaLabel={t(jsonData.editYourDisplayName)} 
            ></Custominput>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={8} aria-label={""} role={""} onKeyPress={handleChange}>
            {/* logo */}
            <Form.Item label={t(jsonData.OrganisationLogo)} className="orgLogo">
              <>
                <Upload.Dragger
                  maxCount={1}
                  openFileDialogOnClick={
                    basicInfoForm.logo !== "" ? false : true
                  }
                  onChange={handleChange}
                  showUploadList={false}
                  beforeUpload={handleBeforeUpload}
                  className="uploadBasicInfo"
                  disabled={!isEditAllowed()}
                  aria-label={t(jsonData.uploadYourOrganizationLogo)}
                >
                  {basicInfoForm.logo !== "" ? uploadImage : uploadButton}
                </Upload.Dragger>
              </>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[56, 32]} className="basicRowGutter">
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {/* pa_line1 */}
            <Custominput
              customLabelClass="labelName formControl"
              labelSubName={t(jsonData.StreetAddress)}
              labelName="pa_line1"
              initialValue={basicInfoForm.pa_line1}
              rules={[{ required: true, message: t(jsonData.StreetAddressRequired)}]}
              placeholder={t(jsonData.streetAddress)}
              name="pa_line1"
              value={basicInfoForm.pa_line1}
              onChange={onValueChange}
              disabled={!isEditAllowed()}
              tabIndex={0}
              ariaLabel={t(jsonData.editYourStreetAddress)} 
              role={""}
            ></Custominput>
          </Col>
        </Row>

        <Row gutter={[56, 32]} className="basicRowGutter">
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {/* locality */}
            <Custominput
              customLabelClass="labelName formControl mt-20"
              labelSubName={t(jsonData.Locality)}
              labelName="locality"
              initialValue={basicInfoForm.locality}
              rules={[{ required: true, message:t(jsonData.LocalityRequired)}]}
              placeholder={t(jsonData.Locality)}
              name="locality"
              value={basicInfoForm.locality}
              onChange={onValueChange}
              disabled={!isEditAllowed()}
              tabIndex={0}
              ariaLabel={t(jsonData.editYourLocality)} 
              role={""}
            ></Custominput>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {/* postcode */}
            <Custominput
              customLabelClass="labelName formControl mt-20"
              labelSubName={t(jsonData.PostalCode)}
              labelName={t(jsonData.PostalCode)}
              initialValue={basicInfoForm.postcode}
              rules={[{ required: true, message: t(jsonData.PostalCodeRequired)}]}
              placeholder={t(jsonData.PostalCode)}
              name="postcode"
              value={basicInfoForm.postcode}
              onChange={onValueChange}
              disabled={!isEditAllowed()}
              tabIndex={0}
              ariaLabel={t(jsonData.editYourPostalCode)}               
              role={""}
            ></Custominput>
          </Col>
        </Row>

        <Row gutter={[56, 32]} className="basicRowGutter">
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {/* country */}
            <Form.Item
              className="dropDownLabel formControl mt-20"
              label={t(jsonData.Country)}
              name="country"
              initialValue={basicInfoForm.country}
              rules={[{ required: true, message:t(jsonData.CountryCodeRequired) }]}
            >
              <Dropdown
                value={basicInfoForm.country}
                optionValue={countriesList}
                onChange={onCountryChange}
                disabled={!isEditAllowed()}
                className="dropDownBorder"
                tabIndex={0}
              ></Dropdown>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              className="dropDownLabel formControl mt-20"
              label={t(jsonData.Language)}
              name="language"
              initialValue={basicInfoForm.language}
              rules={[{ required: true, message: t(jsonData.LanaguageRequired) }]}
            >
              <Dropdown
                value={basicInfoForm.language}
                optionValue={languagesList}
                onChange={onLanguageChange}
                disabled={false}
                className="dropDownBorder"
              ></Dropdown>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[56, 0]} className="basicRowGutter">
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {/* timezone */}
            <Form.Item
              className="dropDownLabel formControl"
              label={t(jsonData.Timezone)}
              name="timezone"
              initialValue={basicInfoForm.timezone}
              rules={[{ required: true, message: t(jsonData.TimezoneRequired)}]}
            >
              <Dropdown
                value={basicInfoForm.timezone}
                optionValue={timezonesList}
                onChange={onTimezoneChange}
                disabled={true}
                className="dropDownBorder"
              ></Dropdown>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {/* culture */}
            <Form.Item
              className="dropDownLabel formControl"
              label={t(jsonData.Culture)}
              name="culture"
              initialValue={basicInfoForm.culture}
              rules={[{ required: true, message: t(jsonData.CultureRequired) }]}
            >
              <Dropdown
                value={basicInfoForm.culture}
                optionValue={culturesList}
                onChange={onCultureChange}
                disabled={true}
                className="dropDownBorder"
              ></Dropdown>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[56, 32]} className="basicRowGutter">
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {/* website */}
            <Custominput
              customLabelClass="labelName formControl"
              labelSubName={t(jsonData.OrganisationWebsite)}
              labelName="website"
              initialValue={basicInfoForm.website}
              placeholder={t(jsonData.OrganisationWebsite)}
              name="website"
              value={basicInfoForm.website}
              onChange={onValueChange}
              disabled={!isEditAllowed()}
              tabIndex={0}
              aria-label={""} 
              role={""}
            ></Custominput>
          </Col>
        </Row>

        <Row gutter={[56, 32]} className="basicRowGutter">
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {/* email */}
            <Form.Item
              label={t(jsonData.SupportEmail)}
              name="email"
              initialValue={basicInfoForm.email}
              rules={[
                {
                  type: 'email',
                  message: t(jsonData.InvalidEmailAddress),
                },
              ]}
              className='labelName formControl mt-20'
            >
              <Custominput
                placeholder={t(jsonData.SupportEmail)}
                name="email"
                value={basicInfoForm.email}
                onChange={onValueChange}
                disabled={!isEditAllowed()}
                tabIndex={0}
                aria-label={""} 
                role={""}
              ></Custominput>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {/* supportURL */}
            <Custominput
              customLabelClass="labelName formControl mt-20"
              labelSubName={t(jsonData.SupportURL)}
              labelName="supportURL"
              initialValue={basicInfoForm.supportURL}
              placeholder={t(jsonData.SupportURL)}
              name="supportURL"
              value={basicInfoForm.supportURL}
              onChange={onValueChange}
              disabled={!isEditAllowed()}
              tabIndex={0}
              aria-label={""} 
              role={""}
            ></Custominput>
          </Col>
        </Row>
      </Form>
    </CompWrapper>
    </>
  );
};

export default BasicInfo;
