/* eslint-disable @typescript-eslint/no-unused-vars */

import React from "react";
import { Row, Col, Card, Popover } from "antd";
import {
  ComponentDate,
  ComponentDescription,
  DeviceContainer,
  DeviceStatus,
  ProductName,
  ProductNamePopover,
} from "./index.styles";
import { ErrorIcon, warningIcon } from "../../../../../images";
import './deviceCompCard.less'
import { getOrganizationDateAndTime, urlModification } from "../../../../../../utility/appUtil";
import { PickerFormat, Status_Type, jsonData } from "../../../../../constants/constants";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppRoutes } from "../../../../../constants/enums";
import { setAdditionalApiPayload } from "../../../../../../redux/reducers/alert/alertReducer";
import { handleEnterKeyPress } from "../../../../../../utility/utils";
import secureLocalStorage from "react-secure-storage";

export const DeviceComponentCard = (props) => {
  const { t } = useTranslation()
  const history = useHistory();
  const dispatch = useDispatch();
  const { component, deviceName, deviceId } = props
  const localeInfo:any = secureLocalStorage.getItem('locale')
  
  const goToAlertFromDeviceHealth = () => {
    const { partId, partInstanceId, status } = component;

    const additionalPayload = {
      alertName: status,
      srcName: deviceId,
      deviceName: deviceName,
      partId: partId,
      partInstanceId: partInstanceId,
      isComponentAlert: true,
    };
    dispatch(setAdditionalApiPayload(additionalPayload));
    history.push(urlModification(AppRoutes.ALERT_CENTRE));
  };

  const getIcon = (type: string, status: string) => {
    switch (type?.toLowerCase()) {
      case Status_Type.WARNING:
        return (
          <DeviceStatus
            tabIndex={0}
            aria-label={`${status}`}
            role={"link"}
            onKeyDown={(e: any) =>
              handleEnterKeyPress(e, goToAlertFromDeviceHealth)
            }
            onClick={goToAlertFromDeviceHealth}
          >
            {status}
            <img className="device-comp-alertImg" src={warningIcon} alt="" />
          </DeviceStatus>
        );
      case Status_Type.ERROR:
        return (
          <DeviceStatus
            tabIndex={0}
            aria-label={`${component?.status}`}
            role={"link"}
            onKeyDown={(e: any) =>
              handleEnterKeyPress(e, goToAlertFromDeviceHealth)
            }
            onClick={goToAlertFromDeviceHealth}
          >
            {status}
            <img className="device-comp-alertImg" src={ErrorIcon} alt="" />
          </DeviceStatus>
        );
    }
  };

  const getDescription = (description: any) => {
    let data = []
    let desc = JSON.parse(description)

    if (Array.isArray(desc)) {
      for (let subText of desc) {
        if (subText.constructor === Object) {
          for (let key in subText) {
            data.push(`${key} : ${subText[key]}`)
          }
        }
        else {
          data.push(subText)
        }
      }
      return data.map((item: any, index: number) => {
        return <p key={index}>{item}</p>
      })
    }
    return desc
  }

  return (
    <Card className="device-comp-card">
      <div className="deviceCompDiv">
        <DeviceContainer component={component?.statusType?.toString().toLowerCase()}  className="device-comp-status"></DeviceContainer>
        <div tabIndex={0} aria-label={component?.name} className="device-comp-info">
          <div>
            <Row className="device-comp-div">
              <Col>
                <Popover overlayClassName="devicePopover"
                  trigger={["hover"]}
                  placement="rightTop"
                  content={<ProductNamePopover>{component?.make}  {component.model}</ProductNamePopover>}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                >
                  <ProductName>{component?.name}</ProductName>
                </Popover>
              </Col>
              <Col className="device-comp-alert">
                {getIcon(component?.statusType, component?.status)}
              </Col>
            </Row>
            {component?.info?.DisplayText?.length ? <ComponentDescription dangerouslySetInnerHTML={{ __html: component?.info?.DisplayText }} /> : ''}
            {component?.timestamp?.length ? <ComponentDate>{t(jsonData.LastUpdatedAt)} : {getOrganizationDateAndTime(component?.timestamp, localeInfo.momentFormat + " " + PickerFormat.TIME)}</ComponentDate> : ''}
          </div>
        </div>
      </div>
    </Card>
  );
};