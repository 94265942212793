/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react";
import { useSelector } from "react-redux";
import { clearSsoFormState, userStateSelector } from "../redux/reducers/user/userReducer";
import Routers from "./router";
import { toast, Slide } from "react-toastify";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { AppService } from "./services/ui/appService";
import Toast from "./components/stateless/common/toast";
import { useHistory } from "react-router-dom";
import { AppStateService, AppStateStores } from "./services/storage/appStateService";
import { AppRoutes } from "./constants/enums";
import { useDispatch } from "react-redux";
import { sso } from "../redux/actions/user/authAction";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next';
import { languageStaticOptions } from "./constants/constants";
import { languagesCode } from "../utility/appUtil";
import { mutateAppUserLanguage } from "../redux/actions/user/userProfileAction";
import secureLocalStorage from "react-secure-storage";
import { cross } from "./images";
import './app.less'
import { CloseOutlined } from "@ant-design/icons";

const App = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation()
  const { store } = props;
  const { formState, appUser, ssoFormState,routePathName } = useSelector(userStateSelector);
  const [isGaInitialize, setIsGaInitialize] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(true)
  const googleId = process.env.REACT_APP_GA_CODE;
  const dispatch = useDispatch();

  const onLogoutCallback = () => {
    //debugger;
    AppStateService.resetState(`persist:${AppStateStores.APP_STATE}`);
    //history.push(AppRoutes.VALIDATE_EMAIL);
    window.location.href = AppRoutes.VALIDATE_EMAIL;
  };
  let key:any = true

  const closeOnEnterKeyPress = (e: any) => {
    if (e?.code === "Enter") {
      e?.target?.click();
    }
  };

  useEffect(() => {
    const subscription = AppService.listenToaster().subscribe((data: any) => {
      toast(data.msg, {
        position: "bottom-right",
        autoClose: data?.isError ? false : 4000,
        className: `toast ${data?.isError && "error-toast"}`,
        bodyClassName:  `toast-body ${data?.isError && "error-toast"}`,
        hideProgressBar: true,
        closeButton:  data?.isError && <CloseOutlined tabIndex={0} onKeyDown={closeOnEnterKeyPress} className="toaster-close-btn"/>,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        transition: Slide,
      });
    });
    if(window?.location.pathname.includes("sso")){
      const token = window?.location.search.slice(4)
      secureLocalStorage.setItem('sso_user',key)
      dispatch(sso(token))
    } else {
      setLoading(false)
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      setIsGaInitialize(false);
    };
  }, []);

  useEffect(() => {
    if (googleId && googleId !== "") {
      //connection to google analytics
      ReactGA.initialize(googleId);
      setTimeout(() => {
        setIsGaInitialize(true);
      }, 100);
    }
  }, []);


  useEffect(() => {
    if (isGaInitialize) {
      if (routePathName !== "" && routePathName !== "/") {
        ReactGA.send({ hitType: "pageview", page:routePathName });
      } else {
        ReactGA.send({ hitType: "pageview", page:"/login" });
      }
    }
  }, [isGaInitialize, routePathName]);



  useEffect(() => {
    if(ssoFormState?.isSuccess) {
     dispatch(clearSsoFormState())
     // dispatch(fetchUserPermissions(appUser.locations[0].role.id))
     window.location.href = AppRoutes.VALIDATE_EMAIL ;
     setLoading(false)
    }
   }, [ssoFormState])


  useEffect(()=>{
    i18next.changeLanguage(appUser?.info?.language?languagesCode(appUser?.info?.language):"English")
    localStorage.setItem('language', `${appUser?.info?.language ? appUser?.info?.language : "English"}`)
  },[appUser])

  useEffect(() => {
    //trigger event on change of localstorage key = language
    const handleStorageChange = (e: StorageEvent) => {
      if (e?.key === "language") {
        const newLang = e?.newValue;
        dispatch(mutateAppUserLanguage(newLang && newLang !==''?newLang:'English'))
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className="App">
    {
      loading ?
      <> </>
      :
      <>
      <Routers
        isAuthenticated={appUser?.isAuthenticated}
        appUser={appUser}
        logoutCallback={onLogoutCallback}
        {...props}
      />
      <Toast />
      </>
    }
    </div>
  );
};

export default App;
